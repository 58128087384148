import {
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { TextField } from "@material-ui/core";
import { useEffect, useState, useContext } from 'react';
import { EventualPayrollModal } from '../../../interfaces/TabEventualPayroll.interfaces';
import { useForm } from '../../../hooks/useForm';
import '../Payroll.css'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from 'date-fns';
import { subMonths } from 'date-fns';
import { es } from 'date-fns/locale';
import { DateRange } from 'react-date-range'
import { useMutation, useQuery } from "@apollo/client";
import { 
          GET_ALL_PAYROLL_GROUP, 
          GET_ALL_EVENTUALPAYROLL,
          GET_ALL_USER_EVENTUAL,
          UPDATE_TOKEN_EVENTUAL,
          CREATE_EVENTUALPAYROLL_WITH_TOKEN,
          GET_ALL_ENTERPRISE
        } from "../../../Querys/querys";
import styles from '../PayrollStyles.module.css'
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import {WarningAlert} from "../../../alerts/WarningAlert"
import EventualPayrollProcessContext from '../../../context/PayrollProcess/EventualPayrollProcessContext';
import { clearEventualPayrollProcess } from '../../../context/PayrollProcess/EventualActions';
import InputAdornment from "@mui/material/InputAdornment";
import { v4 as uuid } from "uuid";
import { ErrorAlert } from "../../../alerts/errorAlert";

const CreaEventualPayroll = (props: any) => {
  const {state, dispatch} = useContext(EventualPayrollProcessContext)
  const [estadoInicial, setEstadoInicial] = useState<any[]>([])
  const [seleccionadas, setSeleccionadas] = useState<any[]>([])
  const [showCalendario, setShowCalendario] = useState(false);
  const [stateCalendario, setStateCalendario] = useState([
    {
      startDate: subMonths(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection"
    }]
  );
  
  const [countDate, setCountDate] = useState(0)
  const [show, setShow] = useState(false);
  const [showPTU, setShowPTU] = useState(false);
  const [showDespensa, setShowDespensa] = useState(false);
  const [showAguinaldo, setShowAguinaldo] = useState(false);
  const [showVacacional, setShowVacacional] = useState(false);
  const [showC, setShowColaboradores] = useState(false);
  const [showSeleccionados, setShowColaboradoresSeleccionado] = useState(false);
  const [seleccionados, setSeleccionados] = useState<any[]>([]);
  const [isCheckE, setIsCheckE] = useState<any[]>([]);
  const [estadoFiltrado, setEstadoFiltrado]:any = useState<any[]>([]);
  const [isCheck, setIsCheck] = useState<any[]>([]);
  const [importe, setImporte] = useState("")
  const [dot, setDot] = useState(0)
  const {data:resultUsers} = useQuery(GET_ALL_USER_EVENTUAL);
  const [totalUsers, setTotalUsers] = useState([]);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [checkAportacionPatronal, setCheckAportacionPatronal] = useState(false)
  const [mesVale, setMesVale] = useState('')
  const [lastYears, setLastYears] = useState<any[]>([]);
  const [lastYearsPTU, setLastYearsPTU] = useState<any[]>([]);
  const resultPayrollGroup = useQuery(GET_ALL_PAYROLL_GROUP);
  const allPayrollGroup = resultPayrollGroup.data?.GET_ALL_PAYROLL_GROUP;

  const [createNewPayroll,{data:datosMutacion, loading:loadingMutation}] = useMutation(CREATE_EVENTUALPAYROLL_WITH_TOKEN, {
    refetchQueries: [{ query: GET_ALL_EVENTUALPAYROLL }],
  });

  const [updateToken] = useMutation(UPDATE_TOKEN_EVENTUAL);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState("");
  
  
  const years = () => {
    const now = new Date();
    console.log(now)
    const year = now.getFullYear();
    console.log(year)
    const lastYears = year - 10;
    const arrayYears = [];
    const arrayYearsPTU = [];
    
    for (let i = lastYears; i <= year; i++) { // Cambiado el <= a <
        arrayYears.push(i);
    }
    for (let i = lastYears; i < year; i++) { // Cambiado el <= a <
      arrayYearsPTU.push(i);
  }
    
    setLastYears(arrayYears);
    setLastYearsPTU(arrayYearsPTU);
}


  
  useEffect(() => {
    if(resultUsers && resultUsers.GET_ALL_USER_EVENTUAL){
      setTotalUsers(resultUsers.GET_ALL_USER_EVENTUAL);
      setAllUsers(resultUsers.GET_ALL_USER_EVENTUAL);
    }
    years()
  }, [resultUsers]);

  const {data: datosListaEmpresa, loading: loadingListaEmpresa, error: errorListaEmpresa} = useQuery(GET_ALL_ENTERPRISE)
  const allListaEmpresa = datosListaEmpresa?.GET_ALL_ENTERPRISE;


  const upDateTotal = ((total:any)=>{
    
    const digitNew = total.replace(importe, "")
    
    let totalWith = ""
    if(total !== ""){
      if(total.length > importe.length  ){
        if(importe.length === 0){
          totalWith = total.replace(/\D/g, "")
        }else{
          if(digitNew === "."){
            if(dot === 0){
              setDot(1)
              totalWith = total
            }else{
              totalWith = importe+digitNew.replace(/\D/g, "")
            }
          }else{
            if(importe === "0"){
              if(digitNew !== "."){
                totalWith = importe
              }else{
                totalWith = total
              }
            }else{
              totalWith = importe+digitNew.replace(/\D/g, "")
            }
          }
        }
      }else{
        if(importe.replace(total, "") === "."){
          setDot(0)
          
        }
        totalWith = total
      }
    }else{
      setDot(0) 
    }
    setImporte(totalWith)
    return totalWith
  })

  const handleSelectEliminar = (e: any) => {
    
    setIsCheck([]);
    setIsCheckE([]);

    let filtradoSeleccionado = seleccionados?.filter((lis: any) => {
        return e.target.checked && !isCheckE.includes(lis._id);
      })
      .map((lis: any) => lis._id);
    setIsCheckE(filtradoSeleccionado);
  };

  const removeClass = () =>{
    const errorfield = document.querySelectorAll("#fild_payroll_type");
        
    errorfield.forEach((field) => {
      field.classList.add(styles.fieldsetInput)
      field.classList.remove(styles.fieldsetInputError)
    });

    const errorImporte = document.querySelectorAll("#MontoRepartirPTU");
        
    errorImporte.forEach((importe) => {
      importe.classList.remove(styles.inputFechaError)
    });

    const errorAnio = document.querySelectorAll("#AnioPTU");
          
    errorAnio.forEach((anio) => {
      anio.classList.add(styles.AnioPTU)
      anio.classList.remove(styles.AnioPTUError)
    });
    const errorAguinaldo = document.querySelectorAll("#AguinaldoPeriod");
    errorAguinaldo.forEach((aguinaldo) => {
      aguinaldo.classList.add(styles.AnioPTU)
      aguinaldo.classList.remove(styles.AnioPTUError)
    });
  }

  const validaCampos = () =>{
    if(formulario.payroll_type === ""){
      const errorfield = document.querySelectorAll("#fild_payroll_type");
      errorfield.forEach((field) => {
        field.classList.add(styles.fieldsetInputError)
        field.classList.remove(styles.fieldsetInput)
      });
      return false
    }else{
      if(formulario.payroll_type === "PTU"){
        if(formulario.MontoRepartirPTU === ""){
          const errorfield = document.querySelectorAll("#MontoRepartirPTU");
          errorfield.forEach((field) => {
            field.classList.add(styles.inputFechaError)
          });
          return false
        }else{
          if(formulario.AnioPTU.toString() === ""){
            const errorfield = document.querySelectorAll("#AnioPTU");
          
            errorfield.forEach((anio) => {
              anio.classList.add(styles.AnioPTUError)
              anio.classList.remove(styles.AnioPTU)
            });
            return false
          }else{
            return true
          }
        }
      }else{
        if(formulario.payroll_type === "Aguinaldo"){
          if(formulario.AguinaldoPeriod.toString() === ""){
            const errorfield = document.querySelectorAll("#AguinaldoPeriod");
            errorfield.forEach((field) => {
              field.classList.add(styles.AnioPTUError)
              field.classList.remove(styles.AnioPTU)
            });
            return false
          }else{
            return true
          }
        }else{
          return true
        }
      }
    }
  }

  const onSumbit = async () => {
    
    props.setSave(false)
    const valida = validaCampos()
    const nuevoEstadoIncial = estadoInicial
    setEstadoInicial(nuevoEstadoIncial)
    console.log(empresaSeleccionada,'Empresa')


    if(showPTU.toString() === 'false'){

      
      if(seleccionados.length > 0 && valida){
        for (const lis of nuevoEstadoIncial){
          const token = lis.group_name+"-"+uuid();
          let existColaborator = 0

          for (const collaborator of seleccionados){
            if(String(collaborator.id_payroll_group) === String(lis.id)){
              existColaborator = existColaborator + 1
              await updateToken({
                variables: {
                  id: String(collaborator._id),
                  token: token
                },
              })
            }
          }
          if(existColaborator > 0){
            createNewPayroll({
              variables: {
                input: {
                  group_name: lis.group_name,
                  payroll_type: formulario.payroll_type,
                  init_date: stateCalendario[0].startDate,
                  end_date: stateCalendario[0].endDate,
                  id_group_payroll: parseInt(lis.id),
                  AportacionPatronal:  formulario.AportacionPatronal.toString() === "true" ? true:false,
                  AnioPTU: formulario.AnioPTU.toString(),
                  MontoRepartirPTU: parseFloat(formulario.MontoRepartirPTU.toString()),
                  AguinaldoPeriod: formulario.AguinaldoPeriod.toString() === ""? null: parseFloat(formulario.AguinaldoPeriod.toString()),
                  token: token,
                  Mes: mesVale,
                  empresa: "0"
                },
              },
            });
          }
        }
  
  
        // SuccessfulAlert({
        //   title: "¡Exito!",
        //   text: "¡Se ha añadido nómina Eventual!",
        // });
        cerrarModal()
      }else{
        props.setSave(true)
        if(seleccionados.length === 0){
          WarningAlert({
            text: "¡Seleccione colaboradores!",
          });
        }
      }
    } else {
      const token = "GENERAL-"+uuid();

      if(seleccionados.length > 0 && valida){

      for (const lis of nuevoEstadoIncial){
        for (const collaborator of seleccionados){
          if(String(collaborator.id_payroll_group) === String(lis.id)){
            await updateToken({
              variables: {
                id: String(collaborator._id),
                token: token
              },
            })
          }
        }
      }
      createNewPayroll({
        variables: {
          input: {
            group_name: 'GENERAL',
            payroll_type: formulario.payroll_type,
            init_date: stateCalendario[0].startDate,
            end_date: stateCalendario[0].endDate,
            id_group_payroll: 0,
            AportacionPatronal:  formulario.AportacionPatronal.toString() === "true" ? true:false,
            AnioPTU: formulario.AnioPTU.toString(),
            MontoRepartirPTU: parseFloat(formulario.MontoRepartirPTU.toString()),
            AguinaldoPeriod: formulario.AguinaldoPeriod.toString() === ""? null: parseFloat(formulario.AguinaldoPeriod.toString()),
            token: token,
            Mes: mesVale,
            empresa: empresaSeleccionada,
          },
        },
      });

      // SuccessfulAlert({
      //   title: "¡Exito!",
      //   text: "¡Se ha añadido nómina Eventual!",
      // });
      cerrarModal()
    }else{
      props.setSave(true)
      if(seleccionados.length === 0){
        WarningAlert({
          text: "¡Seleccione colaboradores!",
        });
      }
    }

 
    }
  }

  const toggleCalendar = () => {
    if(showCalendario === false){
      setShowCalendario(true);
    }
  }

  useEffect(() => {
    if(allUsers){
      setEstadoFiltrado(allUsers)
    }
  }, [allUsers]);

  
  useEffect(() => {
    if(allPayrollGroup){
      setEstadoInicial(allPayrollGroup)
    }
  }, [allPayrollGroup]);


  const eliminaColaboradoresGrupo = (idGroup: any) =>{
    
    const nuevoUniverso = estadoFiltrado?.filter((lis: any) => String(lis?.id_payroll_group) !== String(idGroup))
    setAllUsers(nuevoUniverso)

    const nuevoSelecionadas = seleccionados?.filter((lis: any) => String(lis?.id_payroll_group) !== String(idGroup))
    setSeleccionados(nuevoSelecionadas);
  }

  const agregarColaboradoresGrupo = (idGroup: any) =>{
    
    const existentes: any[] = allUsers
    const nuevoUniverso = totalUsers?.filter((lis: any) => String(lis?.id_payroll_group) === String(idGroup))
    nuevoUniverso?.forEach((lis: any) => {
      existentes.push(lis)
    });
    setAllUsers(existentes)
    
  }

  const eliminaSeleccionada = (objeto: any) => {
    
    eliminaColaboradoresGrupo(objeto.id)
    setSeleccionadas([...seleccionadas, { id: objeto.id, group_name: objeto.group_name, payroll_period: objeto.payroll_period }])
    const nuevoSeleccionadas = estadoInicial.filter((lis) => String(lis?.id) !== String(objeto.id))
    setEstadoInicial(nuevoSeleccionadas)
  }

  const agregaSeleccionadas = (objeto: any) => {
    
    agregarColaboradoresGrupo(objeto.id)
    const agregaSeleccionadas = allPayrollGroup?.filter((lis: any) => String(lis?.id) === String(objeto.id))
    agregaSeleccionadas?.forEach((lis: any) => {
      setEstadoInicial([...estadoInicial, lis])
    })
    const eliminaDisponibles = seleccionadas?.filter((lis: any) => String(lis?.id) !== String(objeto.id))
    setSeleccionadas(eliminaDisponibles)
  }

  const eliminaTodas = () => {

    setAllUsers([]);
    setSeleccionados([]);

    let nuevoSeleccionadas = estadoInicial;

    if(showDespensa){

      nuevoSeleccionadas = allPayrollGroup.filter((lis:any) => lis?.pantry_value === true)

    } else if(show) {

      nuevoSeleccionadas = allPayrollGroup.filter((lis:any) => lis?.saving_fund === true)
       
    } else if (showPTU) {

      nuevoSeleccionadas = allPayrollGroup.filter((lis: any) => lis?.company_name === empresaSeleccionada);

    }
    setSeleccionadas(nuevoSeleccionadas);
    setEstadoInicial([]);
    setEstadoFiltrado([]);

  }

  const agregaTodas = () => {
    setSeleccionadas([]);

    let nuevoSeleccionadas = estadoInicial;


    if(showDespensa){

      nuevoSeleccionadas = allPayrollGroup.filter((lis:any) => lis?.pantry_value === true)

    } else if(show) {

      nuevoSeleccionadas = allPayrollGroup.filter((lis:any) => lis?.saving_fund === true)
       
    } else if (showPTU) {

      nuevoSeleccionadas = allPayrollGroup.filter((lis: any) => lis?.company_name === empresaSeleccionada);

    }

    const nuevoUniverso = totalUsers?.filter((lis: any) => {
      return nuevoSeleccionadas
        .map((worker: any) => {
          return worker.id;
        })
        .includes(String(lis?.id_payroll_group));
    });

    setAllUsers(nuevoUniverso);
    setEstadoInicial(nuevoSeleccionadas);
    setEstadoFiltrado(nuevoUniverso);
  }

  const {
    payroll_type,
    MontoRepartirPTU,
    onChange, formulario, reset
  } = useForm<EventualPayrollModal>({
    payroll_type: "",
    init_date: "",
    end_date: "",
    AportacionPatronal: false,
    AnioPTU: "",
    AguinaldoPeriod: "",
    MontoRepartirPTU: "",
    Empresa: ""
  });

  const cerrarModal = () =>{
    setIsCheck([]);
    setIsCheckE([]);
    setAllUsers(totalUsers)
    setEstadoFiltrado(allUsers);
    setSeleccionados([])
    setSeleccionadas([])
    const nuevoEstadoInicial = allPayrollGroup
    setEstadoInicial(nuevoEstadoInicial)
    reset();
    setShow(false);
    setShowDespensa(false);
    setShowVacacional(false)
    setShowAguinaldo(false)
    setShowPTU(false)
    props.setSave(true)
    removeClass()
    setImporte("")
    setDot(0)
    clearEventualPayrollProcess({},dispatch)
  }

  const rangeSelection = (selection: any)=>{
    
    setStateCalendario(selection);
    if(selection[0].startDate !== selection[0].endDate){
      setShowCalendario(!showCalendario);
      setCountDate(0)
    }else{
      if(countDate > 0){
        setShowCalendario(!showCalendario);
        setCountDate(0)
      }else{
        setCountDate(1)
      }
    }
  }

  const setClass = ((name:any) =>{

    if(name === 'divCollaborators'){
      const divCollaborators = document.querySelectorAll('#divCollaborators');
      divCollaborators.forEach((collaborator) => {
          const p = document.querySelector('#alertCollaborator')
          if(p){
              collaborator.removeChild(p)
          }
      });
    }
  })

  const AllSelect = () => {

    setSeleccionados(allUsers);
    setEstadoFiltrado([]);
    setIsCheck([]);
    setIsCheckE([]);
  };

  const AllDelete = () => {
    
    setSeleccionados([]);
    setEstadoFiltrado(allUsers);
    setIsCheck([]);
    setIsCheckE([]); 
  };

  const handleClickE = (e: any) => {
    
    const { id, checked } = e.target;
    setIsCheckE([...isCheckE, id]);
    if (!checked) {
      setIsCheckE(isCheckE?.filter((lis: any) => lis !== id));
    }
  };

  const EliminardeLista = () => {
    
    const checkedBoxes = document.querySelectorAll(
      '#colaboladores > [type="checkbox"]:checked'
    );
    let workerToDelete: { id: string; colaborator: string }[] = [];
    checkedBoxes.forEach((checkElement) => {
      workerToDelete.push({
        id: checkElement.id,
        colaborator: checkElement.className,
      });
    });

    const seleccionadosEliminar = seleccionados.filter((persona) => {
      return workerToDelete?.map((worker) => {
          return worker.id;
        })?.includes(persona?._id);
    });

    setEstadoFiltrado([...estadoFiltrado, ...seleccionadosEliminar]);

    const nuevoSeleccionadas = seleccionados?.filter((persona) => {
      return !workerToDelete?.map((worker) => {
          return worker.id;
        })?.includes(persona?._id);
    });
    setSeleccionados(nuevoSeleccionadas);
    setIsCheck([]);
    setIsCheckE([]);
    

  };

  const filtrarCollaborator = (colaborador: string) => {

    let datosfiltrados = allUsers?.filter((lis: any) =>
      lis?.NombreUsuario?.toUpperCase().includes(colaborador?.toString()?.toUpperCase())
    );
    if (datosfiltrados?.length <= 0) {
      datosfiltrados = allUsers;
    }
    setEstadoFiltrado(datosfiltrados);
  };

  const handleSelectAll = (e: any) => {
    setIsCheck([]);
    setIsCheckE([]);
  
    let filtradoSeleccionado = estadoFiltrado?.filter((lis: any) => {
    return e.target.checked && !isCheck.includes(lis._id);
    }).map((lis: any) => lis._id);

    setIsCheck(filtradoSeleccionado);
  };

  const handleClick = (e: any) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((lis: any) => lis !== id));
    }
  };

  const AgregarLista = () => {
    const checkedBoxes = document.querySelectorAll(
      '#Universo > [type="checkbox"]:checked'
    );
    let workerToAdd: { id: string }[] = [];
    checkedBoxes.forEach((checkElement) => {
      workerToAdd.push({
        id: checkElement.id,
      });
    });

    const seleccionadosSeleccionar = estadoFiltrado.filter((persona: any) => {
      return workerToAdd
        .map((worker) => {
          return worker.id;
        })
        .includes(persona?._id);
    });

    setSeleccionados([...seleccionados, ...seleccionadosSeleccionar]);

    const nuevoUniverso = estadoFiltrado.filter((persona: any) => {
      return !workerToAdd
        .map((worker) => {
          return worker.id;
        })
        .includes(persona?._id);
    });
    setEstadoFiltrado([...nuevoUniverso]);
    setIsCheck([]);
    setIsCheckE([]);
  };

  const compareClassName = async (varClass:any)=>{
    if(
      varClass !== styles.DivSeleccionadosUniverso 
      && varClass !== styles.fieldsetUpdateFiltro
      && varClass !== styles.checkboxitemAvailableCollaborators
      && varClass !== styles.inputAvailableCollaborators
      && varClass !== styles.labelAvailableCollaborators
      && varClass !== styles.inputUpdate
      && varClass !== styles.imgAvailableCollaborators
      && varClass !== styles.contenedorListaAvailableCollaborators
      && varClass !== styles.listaNombresAvailableCollaborators
      && varClass !== styles.userName
      && varClass !== styles.labelUserName
      && varClass !== styles.botonAgregar
      && varClass !== styles.pc_iconoAgregarDos
      && varClass !== styles.textoAgregar
    ){
      return true
    }else{
      return false
    }
  }

  const actionCompareClassNameSelected = async (varClass:any)=>{
    if(
      varClass === styles.divtotalesSelecionados 
      || varClass === styles.textoDivTotalesSelecionados 
      || varClass ===styles.contenedorTotalesText
    ){
      return true
    }else{
      return false
    }
  }

  const compareClassNameSelected = async (varClass:any)=>{
    if(
      varClass !== styles.DivSeleccionados
      && varClass !== styles.checkboxitemSelecionados
      && varClass !== styles.checkboxitemAvailableCollaborators
      && varClass !== styles.inputSelecionados
      && varClass !== styles.labelSelecionados
      && varClass !== styles.contenedorLista
      && varClass !== styles.listaNombres
      && varClass !== styles.botonEliminarList
      && varClass !== styles.EliminarImg
      && varClass !== styles.textognral
    ){
      return true
    }else{
      return false
    }
  }

  const showHiddenColaboradores = async (e: any) =>{
    if(e.target.parentNode.className !== "MuiDialog-root"){
      if(e?.target.parentNode.className === styles.contenedorSelecioneColaborador || e?.target.parentNode.className === styles.divtotalesUniverso){
        setShowColaboradores(!showC);
      }else{
        const varClass = await compareClassName(e.target.parentNode.className)
        if(varClass === true){
          if(showC){
            setShowColaboradores(false);
          }
        }
      }

      const varShow = await actionCompareClassNameSelected(e?.target.parentNode.className)
      if(varShow){
        setShowColaboradoresSeleccionado(!showSeleccionados);
      }else{
        const varClass = await compareClassNameSelected(e?.target.parentNode.className)
        if(varClass === true){
          if(showSeleccionados){
            setShowColaboradoresSeleccionado(false);
          }
        }
      }
    }
    
  }

  const cambiaEstado = () => {
    if(formulario.AportacionPatronal === true){
      formulario.AportacionPatronal = false
      setCheckAportacionPatronal((current) => current = false)
    }else{
      formulario.AportacionPatronal = true
      setCheckAportacionPatronal((current) => current = true)
    }
  }

  const selectMesVale = (mes:string) =>{
    setMesVale(mes)
  }

  const seleccionValesDespensa =() => {
    setShowDespensa(true);
    setShow(false);
    setShowVacacional(false)
    setShowAguinaldo(false)
    setShowPTU(false)
    agregaTodas()

    const nuevoSeleccionadas = allPayrollGroup.filter((lis:any) => lis?.pantry_value === true)
    setEstadoInicial(nuevoSeleccionadas)

    
    const nuevoUniverso = allUsers?.filter((lis: any) => {
      return nuevoSeleccionadas
        .map((worker: any) => {
          return worker.id;
        })
        .includes(String(lis?.id_payroll_group));
    });

    setAllUsers(nuevoUniverso)
  }


  const handleChangeEmpresa = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setEmpresaSeleccionada(value);
    // Aquí puedes también llamar a una función que filtre `allPayrollGroup`
  };

    useEffect(() => {
      if (empresaSeleccionada !== "") {
        agregaTodas()
      } else {
        // Si no hay empresa seleccionada, mostrar todos los elementos
        setEstadoInicial(allPayrollGroup);
      }



    }, [empresaSeleccionada]);


  const seleccionPtu =() => {
    setShow(false);
    setShowDespensa(false);
    setShowVacacional(false)
    setShowAguinaldo(false)
    setShowPTU(true)
    agregaTodas()

    const nuevoSeleccionadas = allPayrollGroup.filter((lis:any) => lis?.company_name === empresaSeleccionada)
    setEstadoInicial(nuevoSeleccionadas)


    const nuevoUniverso = allUsers?.filter((lis: any) => {
      return nuevoSeleccionadas
        .map((worker: any) => {
          return worker.id;
        })
        .includes(String(lis?.id_payroll_group));
    });

     setAllUsers(nuevoUniverso)

  }

  const seleccionFondoAhorro =() => {
    setShow(true);
    setShowDespensa(false);
    setShowVacacional(false)
    setShowAguinaldo(false)
    setShowPTU(false)
    setEstadoInicial(allPayrollGroup)
    agregaTodas()

    const nuevoSeleccionadas = allPayrollGroup.filter((lis:any) => lis?.saving_fund === true)
    setEstadoInicial(nuevoSeleccionadas)

    const nuevoUniverso = allUsers?.filter((lis: any) => {
      return nuevoSeleccionadas
        .map((worker: any) => {
          return worker.id;
        })
        .includes(String(lis?.id_payroll_group));
    });

    setAllUsers(nuevoUniverso)
  }

  const seleccionPrimaVacacional =() => {
    setShowVacacional(true)
    setShowDespensa(false);
    setShow(false);
    setShowAguinaldo(false)
    setShowPTU(false)
    setEstadoInicial(allPayrollGroup)
    agregaTodas()
  }

  const seleeccionAguinaldo =()=>{    
    setShowAguinaldo(true)
    setShowVacacional(false)
    setShowDespensa(false);
    setShow(false);
    setShowPTU(false)
    setEstadoInicial(allPayrollGroup)
    agregaTodas()
  }

    useEffect(()=>{
    if(datosMutacion?.CREATE_EVENTUALPAYROLL_WITH_TOKEN.mensaje ==='Contribuciones'){

    const tipoNomina = datosMutacion?.CREATE_EVENTUALPAYROLL_WITH_TOKEN.TipoNomina;
    const grupoNomina = datosMutacion?.CREATE_EVENTUALPAYROLL_WITH_TOKEN.GrupoNomina;
    let errorMessage = 'Aguinaldo';
    
    if(tipoNomina === 'Fondo Ahorro' ){

      errorMessage = "" + grupoNomina + " no cuenta con aportaciones en el periodo seleccionado para el grupo de nómina.";
      
    } else if (tipoNomina === 'Aguinaldo' ) {

      errorMessage = "" + grupoNomina + " ya se encuentra un periodo terminado de Aguinaldo.";

    } else if (tipoNomina === 'Prima Vacacional' ) {

      errorMessage = "" + grupoNomina + " ya se encuentra un periodo terminado de Prima Vacacional.";
      
    } else if (tipoNomina === 'PTU' ) {

      errorMessage = "" + grupoNomina + " ya se encuentra un periodo terminado de PTU.";
      
    } else if (tipoNomina === 'Vales de Despensa' ) {
      errorMessage = "" + grupoNomina + " ya se encuentra un periodo terminado de Vales de Despensa.";
      
    }
    
    ErrorAlert({ text: errorMessage });
      cerrarModal();
    }

    if(datosMutacion?.CREATE_EVENTUALPAYROLL_WITH_TOKEN.mensaje ==='Creado'){
      SuccessfulAlert({
        title: "¡Exito!",
        text: "¡Se ha añadido nómina Eventual!",
      }).then(() => {
      })
      cerrarModal();
    }
  },[loadingMutation])


  return (
    <Dialog aria-labelledby="customized-dialog-title" open={state.createEventual} fullWidth={false} maxWidth={"xl"}
    onClick={(e) => {
      showHiddenColaboradores(e)
    }}
    >
      <div className={styles.contenedorTitulo}>
        <span className={styles.tituloCrearModal}>Crear Nómina Eventual</span>
      </div>
      <DialogContent>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.contenedorInputs}>
            <div className={styles.columnaUno}>
              <fieldset className={styles.fieldsetInput} id="fild_payroll_type">
                <legend className={styles.tituloFieldset}>Tipo de nómina</legend>
                <select
                  value={payroll_type}
                  className={styles.selectTipoNomina}
                  onChange={({ target }) =>{
                    onChange(target.value as string, "payroll_type");
                    if (target.value === "PTU") {
                      seleccionPtu()
                    } else if(target.value === "Fondo Ahorro") {
                      seleccionFondoAhorro()
                    } else if(target.value === "Vales de Despensa") {
                      seleccionValesDespensa()
                    }else if(target.value === "Prima Vacacional") {
                      seleccionPrimaVacacional()
                    }else if(target.value === "Aguinaldo") {
                      seleeccionAguinaldo()
                    }
                    removeClass()
                  }
                  }
                  name="payroll_type"
                >
                  <option value="" disabled className="optionSelect">
                    Tipo Nomina
                  </option>
                  <option value="Fondo Ahorro">Fondo Ahorro</option>
                  <option value="PTU">PTU</option>
                  <option value="Vales de Despensa">Vales de Despensa</option>
                  <option value="Prima Vacacional">Prima Vacacional</option>
                  <option value="Aguinaldo">Aguinaldo</option>
                </select>
              </fieldset>
            </div>
            {show}
            {showDespensa?
              <>
                <div>
                <fieldset className={styles.fieldsetInput} id="fild_payroll_type">
                <legend className={styles.tituloFieldset}>Mes</legend>
                  <select
                  className={styles.selectTipoNomina}
                  onChange={(e) => selectMesVale(e.target.value)}
                  defaultValue={"default"}
                  >
                    <option value={"default"} disabled>
                      Seleccione...
                    </option>
                    <option value="Enero">Enero</option>
                    <option value="Febrero">Febrero</option>
                    <option value="Marzo">Marzo</option>
                    <option value="Abril">Abril</option>
                    <option value="Mayo">Mayo</option>
                    <option value="Junio">Junio</option>
                    <option value="Julio">Julio</option>
                    <option value="Agosto">Agosto</option>
                    <option value="Septiembre">Septiembre</option>
                    <option value="Octubre">Octubre</option>
                    <option value="Noviembre">Noviembre</option>
                    <option value="Diciembre">Diciembre</option>
                    </select>
              </fieldset>
                </div>

              </>
            :
            null
            }
            {showPTU ? (   
              <>
                <div className={styles.columnaDos}>
                  <div>
                    <span className={styles.textoFecha}> Monto Repartir </span>
                  </div>
                  <div id="MontoRepartirPTU">
                    <TextField
                      className={styles.inputFecha}
                      name="Total"
                      type="text"
                      value={MontoRepartirPTU}
                      onChange={({ target }) =>{
                        removeClass()
                        onChange( upDateTotal(target.value) as string, "MontoRepartirPTU")
                      }
                      }
                      InputProps={{
                        type: 'text',
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className={styles.columnaTres}>
                  <div>
                    <span className={styles.textoFecha}> Año </span>
                  </div>
                  <div  id="AnioPTU" className={styles.AnioPTU} >
                    <select
                      name="AnioPTU"
                      className={styles.r_select}
                      onChange={({ target }) =>{
                        removeClass()
                        onChange(target.value as string, "AnioPTU")
                      }
                    }
                    >
                      <option value="">Selecciona...</option>
                      {lastYearsPTU?.map( (year:any)=>(
                        <option value={year}>{year}</option>
                       ))
                      }
                    </select>
                  </div>
                  <div>
                    <span className={styles.textoFecha}> Empresa </span>
                  </div>
                  <div  id="Empresa" className={styles.AnioPTU} >
                    <select
                      name="Empresa"
                      className={styles.r_select}
                      value={empresaSeleccionada}
                      onChange={handleChangeEmpresa}
                    >
                      <option value="">Selecciona...</option>
                      {allListaEmpresa?.map( (empresa:any)=>(
                        <option value={empresa.name}>{empresa.name}</option>
                       ))
                      }
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <>
              </>
            )}
            {show ? (
              <>
                <div className={styles.fechas}>
                  <div className={styles.radioitem}>
                    <input
                      type="radio"
                      name="tx"
                      id="ritemas"
                      checked={checkAportacionPatronal}
                      onClick={(e)=> cambiaEstado()}
                    />
                    <label htmlFor="ritemas"> Aportacion Patronal </label>
                  </div>
                </div>
                <div className={styles.columnaTres} onClick={() => toggleCalendar()}>
                  <div className={styles.contenedorFechas}>
                    <div >
                      {stateCalendario.map(home => <div id="hora">
                        <span className={styles.textoFecha}>{new Date(home.startDate).toLocaleDateString()} - {new Date(home.endDate).toLocaleDateString()}</span></div>)}
                    </div>
                    <div className={styles.contenedorCalendario}>
                      <img
                        className={styles.calendario}
                        src="/assets/svg/icono-calendario.svg"
                        onClick={() => toggleCalendar()}
                        alt="Editar" />
                      {
                        (showCalendario ?
                          <DateRange className="Prueba"
                            onChange={(item: any) => {
                              rangeSelection([item.selection])
                            }}
                            moveRangeOnFirstSelection={false}
                            locale={es}
                            ranges={stateCalendario}
                            scroll={{ enabled: true }}
                          /> : null
                        )
                      }
                    </div>
                  </div>
                </div>
              </>
            ):(
              <>
              </> 
            )}
            {showAguinaldo ? (  
              <>
                <div className={styles.columnaDos}>
                  <div>
                    <span className={styles.textoFecha}> Periodo </span>
                  </div>
                  <div id="AguinaldoPeriod" className={styles.AnioPTU} >
                    <select
                      name="AguinaldoPeriod"
                      className={styles.r_select}
                      onChange={({ target }) =>{
                        removeClass()
                        onChange(target.value as string, "AguinaldoPeriod")
                      }
                    }
                    >
                      <option value="">Selecciona...</option>
                      {lastYears?.map( (year:any)=>(
                        <option value={year}>{year}</option>
                       ))
                      }
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  
                </div>
              </>
            )}
          </div>
          <div className={styles.contenedorTitulosColumnas}>
            <div className={styles.contenedorTituloUno}>
              <span className={styles.titulosColumnas}>Todos los grupos de nómina disponibles</span>
            </div>
            <div className={styles.contenedorTituloDos}>
              <span className={styles.titulosColumnas}>Grupos de nóminas seleccionadas</span>
            </div>
          </div>
          <div className={styles.contenedorColumnas}>
            <div>
              <div className={styles.columnaTabla}>
                <ul className='ulDisponibles'>
                  {
                    seleccionadas && seleccionadas?.map((item: any) => {
                      return (
                          <>
                            <li className="listaGruposDisponibles">
                              <div>
                                <span className="textoGrupoDisponibles">
                                  {item.group_name}
                                </span>
                              </div>
                              <div
                                className="botonListaDisponibles"
                                onClick={() => agregaSeleccionadas({ id: item.id, group_name: item.group_name })}>
                                <img className={styles.imagenCursor} src='/assets/icons/icono-flecha-derecha.svg' alt="" />
                              </div>
                            </li>
                          </>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
            <div>
              <div className={styles.botonIzquierda}>
                <img
                  onClick={() => eliminaTodas()}
                  className={styles.imagenCursor}
                  src='/assets/icons/icono-flecha-izquierda.svg' 
                  alt=""/>
              </div>
              <div className={styles.botonDerecha}>
                <img
                  onClick={() => agregaTodas()}
                  className={styles.imagenCursor}
                  src='/assets/icons/icono-flecha-derecha.svg'
                  alt="" />
              </div>
            </div>
            <div className={styles.columnaTabla}>
              <ul className='ulDisponibles'>
                {
                  estadoInicial && estadoInicial?.map((item: any) => {
                    return (
                        <>
                          <li className="listaGruposSeleccionadas">
                            <div
                              className="botonListaSeleccionadas"
                              onClick={() => eliminaSeleccionada({ id: item.id, group_name: item.group_name, payroll_period: item.payroll_period })}>
                              <img className={styles.imagenCursor} src='/assets/icons/icono-flecha-izquierda.svg' alt="" />
                            </div>
                            <div>
                              <span className="textoGrupoSeleccionadas">
                                {item.group_name}
                              </span>
                            </div>
                          </li>
                        </>
                    ) 
                  })
                }
              </ul>
              <div className={styles.contenedorColaborator}>
                <div className={styles.contenedorSelecioneColaborador}>
                  <button
                    className={styles.divtotalesUniverso}
                    onClick={(editar) => {
                      setClass('divCollaborators')
                    }}
                  >
                    <span className={styles.textoDivTotales}>
                      Seleccionar Colaboladores ({estadoFiltrado?.length})
                    </span>
                  </button>
                </div>
                <div className={styles.contenedortotales}>
                  <button className={styles.divtotalesyellow} onClick={AllSelect}>
                      {" "}
                      +{" "}
                  </button>
                  <div className={styles.contenedorTotalesText}>
                    <button className={styles.divtotalesSelecionados} >
                      <span className={styles.textoDivTotalesSelecionados}>
                      {" "}Ver Seleccionados ({seleccionados?.length}){" "}
                      </span>
                    </button>
                  </div>
                  <button className={styles.divtotalesyellowl} onClick={AllDelete}>
                      {" "}
                      -{" "}
                  </button>
                </div>
              </div>
              {showC && estadoFiltrado?.length > 0 ? (
                <div className={styles.DivSeleccionadosUniverso}>
                  <fieldset className={styles.fieldsetUpdateFiltro}>
                    <div className={styles.checkboxitemAvailableCollaborators}>
                      <input
                          type="checkbox"
                          id="erer"
                          className={styles.inputAvailableCollaborators}
                          onChange={(e) => handleSelectAll(e)}
                      ></input>
                      <label htmlFor="erer" className={styles.labelAvailableCollaborators}> </label>
                    </div>
                    <input
                    className={styles.inputUpdate}
                    type="text"
                    id="txtFiltro"
                    placeholder="Colaboradores"
                    onChange={(e) => filtrarCollaborator(e.target.value)}
                    />
                    <img src={`/assets/svg/find.svg`} alt="Buscar" className={styles.imgAvailableCollaborators} />
                  </fieldset>
                  <ul className={styles.contenedorListaAvailableCollaborators}>
                    {estadoFiltrado?.map((lis: any) => (
                      <li className={styles.listaNombresAvailableCollaborators}>
                          <div id="Universo" className={styles.checkboxitemAvailableCollaborators}>
                            <input
                                id={lis._id}
                                key={lis._id}
                                type="checkbox"
                                value={lis._id}
                                checked={isCheck.includes(lis._id)}
                                className={styles.userName}
                                onClick={(e) => handleClick(e)}
                            ></input>
                            <label htmlFor={lis._id} className={styles.labelUserName}> {lis.NombreUsuario}</label>
                          </div>
                      </li>
                    ))}
                  </ul>
                  <br></br>
                  <button className={styles.botonAgregar} onClick={AgregarLista}>
                      <div className={styles.pc_iconoAgregarDos}></div>
                      <span className={styles.textoAgregar}>Agregar</span>
                  </button>
                </div>
              ) : (
                ""
              )}
              {showSeleccionados ? (
                <div className={styles.DivSeleccionados}>
                  {seleccionados?.length > 0 ? (
                    <div className={styles.checkboxitemSelecionados}>
                      <input
                          type="checkbox"
                          id="eliminarTodos"
                          className={styles.inputSelecionados}
                          onChange={(e) => handleSelectEliminar(e)}
                      ></input>
                      <label htmlFor="eliminarTodos" className={styles.labelSelecionados}> Seleccionar Todos </label>
                    </div>
                  ) : (
                      ""
                  )}
                  <ul className={styles.contenedorLista}>
                    {seleccionados?.map((lis: any) => (
                      <li className={styles.listaNombres}>
                        <div id="colaboladores" className={styles.checkboxitemSelecionados}>
                          <input
                              id={lis._id}
                              key={lis._id}
                              checked={isCheckE.includes(lis._id)}
                              type="checkbox"
                              value={lis._id}
                              onClick={(e) => handleClickE(e)}
                              className={lis.NombreUsuario}
                          ></input>
                          <label htmlFor={lis._id} className={styles.labelSelecionados} > {lis.NombreUsuario}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                    <button
                    className={styles.botonEliminarList}
                    onClick={EliminardeLista}
                    >
                      <img
                          src="/assets/icons/eliminar-lista.svg"
                          alt="Eliminar"
                          className={styles.EliminarImg}
                      />
                      Eliminar
                    </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={styles.contenedorBotones}>
            <div className={styles.botonCancelar} onClick={cerrarModal}>
              <span className={styles.textoBoton}>Cancelar</span>
            </div>
            {props.save?
              <div onClick={onSumbit} className={styles.botonCrear}>
                <span className={styles.textoBoton}>Crear</span>
              </div>
            :
              <div className={styles.botonCrear}
              style={{
                opacity: "0.5",
                cursor: "no-drop"
              }}
              >
                <span className={styles.textoBoton}>Crear</span>
              </div>
            }
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default CreaEventualPayroll
