
import { gql } from "@apollo/client";
export const PAYROLL_STAMP = gql`
mutation PAYROLL_STAMP($idPayroll:ID!, $idCols: [ID!]) {
  PAYROLL_STAMP(idPayroll: $idPayroll, idCols: $idCols) {
    message
    token
  }
}`;

export const GET_STATUS_PAYROLL_STAMP = gql`
query GET_STATUS_PAYROLL_STAMP($idPayroll: ID!, $idTracking: ID!){
  GET_STATUS_PAYROLL_STAMP(idPayroll: $idPayroll, idTracking: $idTracking) {
    collaboratorsResponse {
      Colaborador
      UUID
      CodigoError
      MensajeIncidencia
    }
    status
  }  
 }
`;