import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import Box from '@mui/material/Box';
import styles from "../../src/components/Payroll/PayrollStyles.module.css";
import CustomTabs from "../components/Collaborators/Tab/CustomTabs";
import CustomTab from "../components/Collaborators/Tab/CustomTabMain";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PAYROLL,
  GET_PAYROLLCOLLABORATOR,
  UPDATE_PAYROLL_STATE,
  UPDATE_PAYROLL_COLLABORATOR_DELETE,
  GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO,
  UPDATE_RECALCULATE_PAYROLL,
  GET_ALL_PAYROLL_ACTIVE,
  GET_ALL_PAYROLL,
  GET_ALL_COMPANY
} from "..//Querys/querys";
import CrearIncidencias from "../components/Payroll/Modals/CrearIncidenciasModal/CrearIncidencias";
import ShowIncident from "../components/Payroll/Modals/ShowIncident/ShowIncident";
import { formatter } from "../helpers/formatoMoneda";
import PayrollProcessContext from "../context/PayrollProcess/PayrollProcessContext";
import { createincidenciaModal, openShowIncident } from "../context/PayrollProcess/Actions";
import { getDateDay, getDateYear } from "../helpers/Payroll/Payroll";
import CheckPermission from "../helpers/Administradores/Permissions";
import { SuccessfulAlert } from '../alerts/successAlerts';
import { CreateExcelFilePayroll } from "../components/Payroll/Modals/ExcelPayroll";
import iconoIncident from "../assets/svg/OpenReg.svg";
import LockedSystem from "../alerts/LockedSystem";
import openBlok from "../assets/svg/openBlok.svg";
import { LoadingAlert } from "../alerts/loadingAlerts";
import { WarningAlertTimbre } from "../alerts/WarningAlertTimbre";
import { removeAccents } from '../helpers/RemoveAccents';

export const InitPayroll = () => {
  const { id, tab } = useParams<any>();
  const [filtrado, setFiltrado] = useState<any[]>([]);
  const [canRecalculate, setCanRecalculate] = useState(true);
  const [isCheck, setIsCheck] =  useState<any[]>([])
  const [saveIncident, setSaveIncident] = useState(true);
  
  const { dispatch: payrollDispatch } = useContext(
    PayrollProcessContext
  );
  const history = useHistory();
  
  const {
    loading: payrollCollaboratorLoading,
    data: resultPayrollCollaborator
  } = useQuery(GET_PAYROLLCOLLABORATOR, {
    variables: { getPayrollcollaboratorId: id },
  });

  const resultPayrollCollaboratorExcell = useQuery(GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO, {
    variables: { getPayrollcollaboratorExcellCalculoId: id },
  });

  const { loading, data } = useQuery(GET_PAYROLL, {
    variables: { getPayrollId: id },
  });
  const PayrollData = data?.GET_PAYROLL;

  const [updateCollaboratorStatus] = useMutation(UPDATE_PAYROLL_COLLABORATOR_DELETE, {
    refetchQueries: [
      { query: GET_PAYROLLCOLLABORATOR, variables:{getPayrollcollaboratorId: id}},
      { query: GET_PAYROLL, variables:{getPayrollId: id}},
      { query: GET_ALL_PAYROLL },
      { query: GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO, variables:{getPayrollcollaboratorExcellCalculoId: id}}
    ],
  });

  const [recalculatePayroll] = useMutation(UPDATE_RECALCULATE_PAYROLL, {
    refetchQueries: [
      { query: GET_PAYROLLCOLLABORATOR, variables:{getPayrollcollaboratorId: id}},
      { query: GET_PAYROLL, variables:{getPayrollId: id}},
      { query: GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO, variables:{getPayrollcollaboratorExcellCalculoId: id}}
    ],
  });
  const allPayrollCollaborator = resultPayrollCollaborator?.GET_PAYROLLCOLLABORATOR;

  const allPayrollCollaboratorExcell = resultPayrollCollaboratorExcell.data?.GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO;

  /*--------------------------sistemaActivo--------------------*/
  const { data: resultCompany, startPolling, stopPolling } = useQuery(GET_ALL_COMPANY);
  const myCompany = resultCompany?.GET_ALL_COMPANY;
  const [sistemaActivo, setSistemaActivo] = useState<any>(true);

  useEffect(()=>{
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[startPolling,stopPolling])

  useEffect(() => {
    if(myCompany){
      setSistemaActivo(myCompany[0]?.SistemaActivo)
    }
  }, [myCompany]);
  /*--------------------------sistemaActivo--------------------*/

  useEffect(() => {
    setFiltrado(allPayrollCollaborator);
  }, [allPayrollCollaborator]);

  useEffect(() => {
    if(filtrado){
      let varRecalculate = 1
      filtrado.forEach((item:any) => {
        if((item.UUID && item.UUID !== "") || (item.dispersionStatus === "LIQUIDADO")){
          varRecalculate = 0
        }
      });
      if(varRecalculate === 0){
        setCanRecalculate(false)
      }
    }
  }, [filtrado]);

  const createModal = (id: any) => {
    createincidenciaModal({ _id: id, createIncidencia: true }, payrollDispatch);
  };

  const createModalShowIncident = (idPayroll: any, idCollaborator: any, UUID: any, dispersionStatus: any) => {
    
    const varDelete: any = (!UUID || UUID === "") && (dispersionStatus !== "LIQUIDADO") ? true : false;
    
    openShowIncident({ idPayroll, idCollaborator, showDataCancelTimbre:true, varDelete }, payrollDispatch);
  }

  const handleChange = () => {
  };

  
  const datosFiltrados = (e: any) => {
    const array=[...allPayrollCollaborator]       
    if (e !== "") {
      let expresion = new RegExp(`${removeAccents(e)}.*`, "i");
        const nuevoFiltrado = array.filter((lis: any) =>
        expresion.test(removeAccents(lis.colaborator))
      );      
      setFiltrado(nuevoFiltrado);
    } else {
      setFiltrado(allPayrollCollaborator);
    }
  };

  const [updatePayroll] = useMutation(UPDATE_PAYROLL_STATE,{
    refetchQueries: [{ query: GET_ALL_PAYROLL_ACTIVE }, { query: GET_ALL_PAYROLL }]
  });

  const updateState = (id: any) => {
    if(!PayrollData?.dateFinish ){
      updatePayroll({
        variables: {
          input: {
            id: id,
            statusProgress: "Dispersar",
          },
        },
      });
    } 
  };


  const recalculaNomina = async(id: any) => {
    LoadingAlert({ title: "Calculando..." });
    const res=await recalculatePayroll({
        variables: {
          updateRecalculatePayrollId: id
        },
      });
      const { UPDATE_RECALCULATE_PAYROLL } = res.data;
      if (UPDATE_RECALCULATE_PAYROLL === 'Registro Actualizado') {
        SuccessfulAlert({ text: 'Cálculo actualizado' });
      } else {
        WarningAlertTimbre({ text: 'Error en el cálculo' });
      }            
  };

  const handleSelectAll = (e:any) =>{

    if(!e.target.checked){
      setIsCheck([])
    }

    let filtradoSeleccionado = (allPayrollCollaborator.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id) && (!lis.UUID || lis.UUID === "") && (lis.dispersionStatus !== "LIQUIDADO")
    }).map((lis:any) =>(lis.id)))
    setIsCheck(filtradoSeleccionado)

  }

  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }

  }

  const handleOnExport = () => {
    CreateExcelFilePayroll(allPayrollCollaboratorExcell);
  };

  const eliminarColaborador = async () =>{
    if(isCheck.length > 0){
      for (const payrollDelete of isCheck){
        await updateCollaboratorStatus({
          variables: {
            id: String(payrollDelete)
          },
        })
      }
    }
    SuccessfulAlert({text: "Registro eliminado con éxito"})
    setIsCheck([])

  }  

  return (
    <>
      {!sistemaActivo ? (
        <LockedSystem/>
      ) : null}
      <Box mt={3} ml={5} className="Title">
        Nóminas 
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            //Tiene que ser 1 para que se muestre la pestaña de pre-nómina
            value={1}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}></div>
            </div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
          </div>
        </div>
        <div className={styles.pc_contenedorBotones}>
          {(CheckPermission("Nominas.PreNomina.Ver.Calcular.Calcular") && 
          sistemaActivo && 
          canRecalculate && 
          PayrollData?.dateFinish === null && 
          PayrollData?.Incident === 0) ?
            <button className={styles.pc_botonCalcular}
              onClick={() => {
                recalculaNomina(id);
              }}>
              <div className={styles.pc_iconoCalcular}></div>
              Calcular
            </button>
            : null
          }

          {CheckPermission("Nominas.PreNomina.Ver.Calcular.IrADispersar")&&(
          <button
            className={styles.pc_botonDispersar}
            onClick={() => {
              updateState(id);
              history.push(`/payrolldispersar/${id}/${tab}`);
            }}
          >
            <div>Ir a Dispersar</div>
            <div className={styles.pc_iconoDispersar}></div>
          </button>
          )}
        </div>
      </div>
      <div className={styles.pc_contenedorPrincipal}>
        <div className={styles.pc_tituloContenedorPrincipal}>
          <div className={styles.pc_contenedorNombre}>
            <div>
              <span className={styles.pc_tituloPrincipal}>{tab}</span> 
            </div>
            {loading ? null : (
              <div>
                <span className={styles.pc_tituloPeriodo}>
                  Periódo: &nbsp;{getDateDay(PayrollData?.init_date)}
                </span>
                &nbsp;-&nbsp;
                <span className={styles.pc_tituloPeriodo}>
                  {getDateDay(PayrollData?.end_date)}
                </span>
                &nbsp;
                <span className={styles.pc_tituloPeriodo}>
                  {getDateYear(PayrollData?.end_date)}
                </span>
              </div>
            )}
          </div>
          <div>
          {(CheckPermission("Nominas.PreNomina.Ver.Calcular.AgregarIncidencia") && sistemaActivo)?
            PayrollData?.dateFinish ? null
              :<button
                id="payroll_6"
                className={styles.pc_botonAgregarIncidencias}
                onClick={() => createModal(id)}
              >
                <div className={styles.pc_iconoAgregarDos}></div>
                <div>Agregar Incidencia</div>
              </button>
          :null
          }
          </div>
        </div>
        <div className={styles.pc_contenedorBuscadorDescargar}>
          <div className={styles.pc_contenedorBuscador}>
            <input
              type="text"
              placeholder="Buscar al colaborador por nombre"
              className={styles.pc_inputBuscador}
              onChange={(e) => datosFiltrados(e.target.value)}
            ></input>
            <div className={styles.pc_iconoBuscador}></div>
          </div>
          <button
            className={styles.pc_botonDescargar}
            onClick={() => handleOnExport()}
          >
            <div className={styles.pc_iconoDescargar}></div>
            <div>Descargar reporte</div>
          </button>
        </div>
        <div
        style={{
          marginBottom: "20px",
        }}
        > {
            (PayrollData?.dateFinish || !sistemaActivo) ? null
            :  (isCheck.length > 0)?
                <button className={styles.pc_botonEliminar}
                onClick={() => eliminarColaborador()}
                >
                    Eliminar
                  </button>
              :
                <button className={styles.pc_botonEliminarInactivo}>
                    Eliminar
                </button>
          }
        </div>
        <div className={styles.pc_contenedorTabla}>
          <table className={styles.pc_tablaPrincipal}>
            <thead className={styles.pd_contenedorTitulos}>
              <td></td>
              <td className={styles.pc_columnaTitulos}>
                <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id="check"
                          type="checkbox"
                          onChange={(e) => handleSelectAll(e)}
                        ></input>
                        <label htmlFor="check"> Colaborador </label>
                </div>
              </td>
              <td className={styles.pc_columnaTitulos}>Proyecto/Área</td>
              <td className={styles.pc_columnaTitulos}>Ingreso bruto mensual</td>
              <td className={styles.pc_columnaTitulos}>Ingreso total</td>
              <td className={styles.pc_columnaTitulos}>Ingreso diario</td>
              <td className={styles.pc_columnaTitulos}>
                Salario diario gravable
              </td>
              <td className={styles.pc_columnaTitulos}>
                Salario diario Integrado
              </td>
              <td className={styles.pc_columnaTitulos}>Días del período</td>
              <td className={styles.pc_columnaTitulos}>Días laborados</td>
              <td className={styles.pc_columnaTitulos}>Sueldo bruto</td>
              <td className={styles.pc_columnaTitulos}>Subsidio al empleo</td>
              <td className={styles.pc_columnaTitulos}>ISR</td>
              {/* <td className={styles.pc_columnaTitulos}>Ajuste ISR</td>
              <td className={styles.pc_columnaTitulos}>Ajuste Subsidio</td> */}
              <td className={styles.pc_columnaTitulos}>
                Cuota IMSS trabajador
              </td>
              <td className={styles.pc_columnaTitulos}>Fondo de ahorro</td>
              <td className={styles.pc_columnaTitulos}>Crédito INFONAVIT</td>
              <td className={styles.pc_columnaTitulos}>Incidencias</td>
              <td className={styles.pc_columnaTitulos}>Sueldo neto gravable</td>
              <td className={styles.pc_columnaTitulos} style={{paddingRight: '20px'}}>
                Total a pagar no gravable
              </td>
            </thead>
            <tbody>
              {!payrollCollaboratorLoading && filtrado?.map((collaborator: any, index: any) => (
                <>
                  <TableRow key={index} collaborator={collaborator} handleClick={handleClick} isCheck={isCheck} createModalShowIncident={createModalShowIncident} formatter={formatter}></TableRow>
                </>
              ))}
            </tbody>
          </table>
        </div>
        {
          payrollCollaboratorLoading && (
            <div className={styles.loader_container}>
              <div className={styles.loading} />
            </div>
          )
        }
        <div>
          <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado}>
              <div
                className={styles.pd_contenedorRegreso}
                onClick={() => history.push(`/payroll`)}
              >
                <div className={styles.pd_botonRegreso}></div>
                <div>Regresar a "Pre-n&oacute;mina"</div>
              </div>
              <button
                className={styles.pd_botonSalir}
                onClick={() => history.push(`/payroll`)}
              >
                Salir
              </button>
            </div>
          </div>
        </div>
      </div>
      <CrearIncidencias saveIncident={saveIncident} setSaveIncident={setSaveIncident} />
      <ShowIncident/>
    </>
  );
};

function TableRow({collaborator, isCheck, handleClick, createModalShowIncident, formatter }:{collaborator:any, isCheck:any, handleClick:any, createModalShowIncident:any, formatter: any  }){

  const [isOpen, setIsOpen] = useState(false);
  const showButtons = collaborator?.Incidencias;

  return (
    <>
      <tr>
        <td>    
          {
            collaborator.salaries && collaborator.salaries.length > 0 && (
              <img src={openBlok} onClick={()=>{setIsOpen(!isOpen)}} style={{cursor:'pointer'}} alt=""/>
            )
          }
        </td>
        <td className={styles.pc_columnaDatos}>
          <div id="colaboladores" className={styles.checkboxitem}>
            <input
              id={collaborator.id}
              key={collaborator.id}
              type="checkbox"
              checked ={isCheck.includes(collaborator.id)}
              onChange={(e) => handleClick(e)}
              value={collaborator.id}
              disabled={(!collaborator.UUID || collaborator.UUID === "") && collaborator.dispersionStatus !== "LIQUIDADO" ? false: true}
            ></input>
            <label htmlFor={collaborator.id}>  {collaborator.colaborator} </label>
          </div>
        </td>
        <td className={styles.pc_columnaDatos}>
          {collaborator.area}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.netIncome)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.totalIncome)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.DS)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.GDS)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.ISD)} 
        </td>
        <td className={styles.pc_columnaDatos}>
          {collaborator.periodDays}
        </td>
        <td className={styles.pc_columnaDatos}>
          {collaborator.workingDays}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.grossSalary)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.Subsidy)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.ISR)}
        </td>
        {/*<td className={styles.pc_columnaDatos}>
          {formatter(collaborator.CausedSubsidy)}
        </td>
         <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.CausedISR)}
        </td> */}
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.IMSS)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.SavingsFund)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.INFONAVIT)}
        </td>
        {showButtons ? (
        <td className={styles.pc_columnaDatos}>
          <Button onClick={()=>{
            createModalShowIncident(collaborator.idPayroll, collaborator.id_Collaborator, collaborator.UUID, collaborator.dispersionStatus)
          }}
          style={{cursor: 'pointer'}}
          role="button"
          >
            {formatter(collaborator.incident)}
          </Button>
          <Button
            style={{ marginRight: "5px", marginLeft: "5px" }}
            role="button"
            onClick={()=>{
              createModalShowIncident(collaborator.idPayroll, collaborator.id_Collaborator, collaborator.UUID, collaborator.dispersionStatus)
            }}
          >
            <img
              src={iconoIncident}
              style={{ marginRight: "auto", marginLeft: "auto" }}
              className="cursor"
              alt="DownloadFile"
            />
          </Button>
        </td>
             ):
          <td className={styles.pc_columnaDatos}>
          <Button onClick={()=>{}}
          style={{cursor: 'pointer'}}
          role="button"
          >
            {formatter(collaborator.incident)}
          </Button>
          </td>
        }

        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.netIncomeTaxable)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.TotalIncomeNotTaxable)}
        </td>
      </tr>
      {
        isOpen && (
          <>
            {
              collaborator.salaries.map((salary:any, key:number)=>(
                <tr key={key}>
                  <td></td>
                  <td className={styles.pc_subColumnaDatos}>
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {
                      salary.isFrom
                    }
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {formatter(salary.netIncome)}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {formatter(salary.totalIncome)}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {formatter(salary.DS)}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {formatter(salary.GDS)}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {salary.periodDays}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {salary.workingDays}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {formatter(salary.Subsidy)}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {formatter(salary.ISR)}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {formatter(salary.IMSS)}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {formatter(salary.SavingsFund)}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {formatter(salary.INFONAVIT)}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {formatter(salary.netIncomeTaxable)}
                  </td>
                  <td className={styles.pc_subColumnaDatos}>
                    {formatter(salary.TotalIncomeNotTaxable)}
                  </td>
                </tr>
              ))
            }
          </>
        )
      }
    </>
  )
}

export default InitPayroll;
