const Excel = require("exceljs");

export const CreateExcelFilePayroll = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteCalculo = workbook.addWorksheet("Reporte del Calculo");

  const width = 40;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteCalculo.columns = [
    { header: "NOMBRE", key: "name", width },
    { header: "AREA", key: "area", width },
    //{ header: "SALARIO NETO", key: "SalarioNeto", width },
    { header: "SALARIO BRUTO MENSUAL", key: "SalarioNeto", width },
    { header: "SALARIO DIARIO", key: "SalarioDiario", width },
    { header: "SALARIO DIARIO GRAVABLE", key: "SalarioDiarioGravable", width },
    { header: "SALARIO DIARIO INTEGRADO", key: "SalarioDiarioIntegrado", width },
    { header: "DÍAS PERIODO", key: "DiasPeriodo", width },
    { header: "DÍAS TRABAJADOS", key: "DiasTrabajados", width },
    { header: "SALARIO BRUTO", key: "SalarioBruto", width },
    { header: "SUBSIDIO", key: "subsidio", width },
    { header: "ISR", key: "isr", width },
    { header: "IMSS", key: "imss", width },
    { header: "FONDO DE AHOROO", key: "FondoAhorro", width },
    { header: "INFONAVIT", key: "infonavit", width },
    { header: "INCIDENCIAS", key: "incidencias", width },
    { header: "NETO GRAVABLE", key: "NetoGravable", width },
    { header: "NETO NO GRAVABLE", key: "NetoNoGravable", width },
  ];

  let letter = 65;
  ReporteCalculo.columns.forEach(() => {
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {
    let name = item?.Colaborador.toUpperCase() || "";

    ReporteCalculo.addRow({
      name,
      area: item.area,
      SalarioNeto: item.SalarioNeto,
      SalarioDiario: item.SalarioDiario,
      SalarioDiarioGravable: item.SalarioDiarioGravable,
      SalarioDiarioIntegrado: item.ISD,
      DiasPeriodo: item.DiasPeriodo,
      DiasTrabajados: item.DiasTrabajados,
      SalarioBruto: item.SalarioBruto,
      subsidio: item.Subsidio,
      isr: item.ISR,      
      imss: item.IMSS,
      FondoAhorro: item.FondoDeAhorro,
      infonavit: item.INFONAVIT,
      incidencias: item.Incidencias,
      NetoGravable: item.NetoGravable,
      NetoNoGravable: item.NetoNoGravable
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteCalculo.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

export const CreateExcelFilePayrollReport = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteCalculo = workbook.addWorksheet("Reporte de Nómina");
  const width = 40;
  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteCalculo.columns = [
    { header: "PRENÓMINA", key: "PreNomina", width },
    { header: "GRUPO NÓMINA", key: "GrupoNomina", width },
    { header: "EMPRESA", key: "RazonSocial", width },
    { header: "PERCEPCIÓN", key: "percepcion", width },
    { header: "DEDUCCIÓN", key: "deduccion", width },
    { header: "TOTAL", key: "total", width },
  ];

  let letter = 65;
  ReporteCalculo.columns.forEach(() => {
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {
    let RazonSocial = item?.RazonSocial.toUpperCase() || "";

    ReporteCalculo.addRow({
      RazonSocial,
      PreNomina: item.GrupoNomina,
      GrupoNomina: item.GrupoNomina,
      percepcion: item.percepcion,
      deduccion: item.deduccion,
      total: item.total,
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteNómina.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

export const CreateExcelFileIncidentLayout = (dataColaborador: any,dataPercepcion: any,dataDeduccion: any,
  dataTipoConcepto: any,dataTipoIncidencia: any,dataTipoBase: any,dataPeriodico: any) => {
  const workbook = new Excel.Workbook();
  const ReporteCalculo = workbook.addWorksheet("Layout Incidencias");
  const Colaboradores = workbook.addWorksheet("Colaboradores");
  const Percepciones = workbook.addWorksheet("Percepciones");
  const Deducciones = workbook.addWorksheet("Deducciones");
  const TipoConcepto = workbook.addWorksheet("Tipo Concepto");
  const TipoIncidencia = workbook.addWorksheet("Tipo Incidencia");
  const TipoBase = workbook.addWorksheet("Tipo Base");
  const Periodico = workbook.addWorksheet("Incidencia Periodica");
  const width = 20;
  const widthMax = 30;
  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteCalculo.columns = [
    { header: "ID COLABORADOR", key: "", width },
    { header: "TIPO CONCEPTO", key: "", width },
    { header: "TIPO INCIDENCIA", key: "", width },
    { header: "DIAS", key: "", width },
    { header: "HORAS", key: "", width },
    { header: "FECHA", key: "", width },
    { header: "CANTIDAD", key: "", width },
    { header: "TIPO BASE", key: "", width },
    { header: "ID CONCEPTO", key: "", width },
    { header: "COMENTARIO", key: "", width },
    { header: "PERIODICO", key: "", width },
    { header: "FECHA FIN PERIODICIDAD", key: "", width }
  ];

  Colaboradores.columns = [
    { header: "ID COLABORADOR", key: "idColaborador", widthMax, },
    { header: "NOMBRE COLABORADOR", key: "NombreColaborador", widthMax },
  ];

  Percepciones.columns = [
    { header: "ID PERCEPCIÓN", key: "idPercepcion", widthMax, },
    { header: "NOMBRE PERCEPCIÓN", key: "NombrePercepcion", widthMax },
  ];

  Deducciones.columns = [
    { header: "ID DEDUCCIÓN", key: "idDeduccion", widthMax, },
    { header: "NOMBRE DEDUCCIÓN", key: "NombreDeduccion", widthMax },
  ];

  TipoConcepto.columns = [
    { header: "TIPO CONCEPTO", key: "TipoConcepto", width, }
  ];

  TipoIncidencia.columns = [
    { header: "TIPO INCIDENCIA", key: "TipoIncidencia", width, }
  ];

  TipoBase.columns = [
    { header: "TIPO BASE", key: "TipoBase", width, }
  ];

  Periodico.columns = [
    { header: "INCIDENCIA PERIODICA", key: "Periodico", width, }
  ];

  let letter = 65;
  ReporteCalculo.columns.forEach(() => {
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  letter = 65;
  Colaboradores.columns.forEach(() => {
    Colaboradores.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    Colaboradores.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    Colaboradores.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  letter = 65;
  Percepciones.columns.forEach(() => {
    Percepciones.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    Percepciones.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    Percepciones.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  letter = 65;
  Deducciones.columns.forEach(() => {
    Deducciones.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    Deducciones.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    Deducciones.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  letter = 65;
  TipoConcepto.columns.forEach(() => {
    TipoConcepto.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    TipoConcepto.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    TipoConcepto.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });
  
  letter = 65;
  TipoIncidencia.columns.forEach(() => {
    TipoIncidencia.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    TipoIncidencia.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    TipoIncidencia.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  letter = 65;
  TipoBase.columns.forEach(() => {
    TipoBase.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    TipoBase.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    TipoBase.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  letter = 65;
  Periodico.columns.forEach(() => {
    Periodico.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    Periodico.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    Periodico.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  dataColaborador.forEach((item: any) => {
    Colaboradores.addRow({
      idColaborador: item?.idColaborador,
      NombreColaborador: item?.NombreColaborador,
    });
  });

  dataPercepcion.forEach((item: any) => {
    Percepciones.addRow({
      idPercepcion: item?.idPercepcion,
      NombrePercepcion: item?.NombrePercepcion,
    });
  });

  dataDeduccion.forEach((item: any) => {
    Deducciones.addRow({
      idDeduccion: item?.idDeduccion,
      NombreDeduccion: item?.NombreDeduccion,
    });
  });

  dataTipoConcepto.forEach((item: any) => {
    TipoConcepto.addRow({
      TipoConcepto: item?.TipoConcepto,
    });
  });

  dataTipoIncidencia.forEach((item: any) => {
    TipoIncidencia.addRow({
      TipoIncidencia: item?.TipoIncidencia,
    });
  });

  dataTipoBase.forEach((item: any) => {
    TipoBase.addRow({
      TipoBase: item?.TipoBase,
    });
  });

  dataPeriodico.forEach((item: any) => {
    Periodico.addRow({
      Periodico: item?.Periodico,
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'LayoutIncidencias.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};
 
export const CreateExcelFileMonthlyISR = (data: any) => {

  const workbook = new Excel.Workbook();
  const ReporteCalculo = workbook.addWorksheet("Reporte del Calculo");

  const width = 40;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteCalculo.columns = [
    { header: "NOMBRE", key: "name", width },
    { header: "AREA", key: "area", width },
    { header: "BASE GRAVABLE", key: "baseGravable", width },
    { header: "LIMITE INFERIOR", key: "LimiteInferior", width },
    { header: "EXCEEDENTE", key: "Excedente", width },
    { header: "TASA", key: "Tasa", width },
    { header: "ISR MARGINAL", key: "ISRMarginal", width },
    { header: "CUOTA FIJA", key: "CuotaFija", width },
    { header: "ISR CAUSADO", key: "ISRCausado", width },
    { header: "SUPE CAUSADO", key: "SUPECausado", width },
    { header: "ISR ACREDITADO", key: "ISRAcreditado", width },
    { header: "ISR", key: "ISR", width },
    { header: "SUPE", key: "SUPE", width },
    { header: "TOTAL DIFERENCIA", key: "TotalDiferencia", width }
  ];

  let letter = 65;
  ReporteCalculo.columns.forEach(() => {
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {
    let name = item?.Colaborador.toUpperCase() || "";

    ReporteCalculo.addRow({
      name,
      area: item.area,
      baseGravable: item.baseGravable,
      LimiteInferior: item.LimiteInferior,
      Excedente: item.Excedente,
      Tasa: item.Tasa,
      ISRMarginal: item.ISRMarginal,
      CuotaFija: item.CuotaFija,
      ISRCausado: item.ISRCausado,
      SUPECausado: item.SUPECausado,
      ISRAcreditado: item.ISRAcreditado,
      ISR: item.ISR,
      SUPE: item.SUPE,
      TotalDiferencia: item.TotalDiferencia
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteCalculoISRMensual.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });

}