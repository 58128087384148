/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import styles from "../../src/components/Payroll/PayrollStyles.module.css";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import {
  GET_SETTLEMENTCOLLABORATOR,
  GET_SETTLEMENTPAYROLL,
  UPDATE_SETTLEMENTPAYROLL_STATE,
  UPDATE_SETTLEMENT_COLLABORATOR_LETTER,
  CREATE_MOVIMIENTOSIDSE
} from "..//Querys/querys";
import { useContext, useEffect, useState, useRef } from "react";
import { formatter } from "../helpers/formatoMoneda";
import { openFinish } from "../context/PayrollProcess/Actions";
import CheckPermission from "../helpers/Administradores/Permissions";
import PayrollProcessContext from "../context/PayrollProcess/PayrollProcessContext";
import { postFileGeneral } from '../services/candidateService';
import { ErrorAlert } from "../alerts/errorAlert";
import { LoadingAlert } from "../alerts/loadingAlerts";
import Swal from "sweetalert2";
import { server } from "../global/server";
import axios from "axios";

const InitSettlementL = () =>{
  const {dispatch} = useContext(PayrollProcessContext)
  const { id, tab,tipo } = useParams<any>();
  const history = useHistory();
  const [impoTotal,setImpoTotal] = useState(0)   
  const [filesAdd,setFilesAdd]= useState<any>([])

  const handleChange = () =>{
  }
  /*Llamadassss*/
  const resultSettlement = useQuery(GET_SETTLEMENTPAYROLL, {
    variables: { getSettlementpayrollId: id },
  });
  const allSettlement = resultSettlement.data?.GET_SETTLEMENTPAYROLL;
  const [updatePayroll] = useMutation(UPDATE_SETTLEMENTPAYROLL_STATE);

  const [settlement_payrolls, setSettlement_payrolls] = useState<any>([]);
  const resultSettlementCollaborator = useQuery(GET_SETTLEMENTCOLLABORATOR, {
    variables: { getSettlementPayrollcollaboratorId: id },
  });
  const [updateLetters, {data:responseLetter}] = useMutation(UPDATE_SETTLEMENT_COLLABORATOR_LETTER,{
    refetchQueries:[
      {
        query: GET_SETTLEMENTCOLLABORATOR,
        variables:{
          getSettlementPayrollcollaboratorId: id
        }
      }
    ]
  });
  const [createIDSEMovement] = useMutation(CREATE_MOVIMIENTOSIDSE,{
    refetchQueries:[
      {
        query: GET_SETTLEMENTCOLLABORATOR,
        variables:{
          getSettlementPayrollcollaboratorId: id
        }
      }
    ]
  });
  const allSettlementCollaborator = resultSettlementCollaborator.data?.GET_SETTLEMENTCOLLABORATOR;
  const hiddenFileInput:any = useRef(null);
  const hiddenFileInputQuit:any = useRef(null);
  const [selectedType, setType ] = useState(tipo);
  const [inIdse,setInIdse] = useState(false) 

  useEffect(()=>{
      switch (tipo) {
      case 'Finiquito':
        if(filesAdd.length >= 2 && inIdse){    
          createMovement()    
        }
      break;
      case 'Liquidación':
        if(filesAdd.length === 1 && inIdse){    
          createMovement()    
        }
     break; 
     }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define, react-hooks/exhaustive-deps
  }, [filesAdd]);

  const createMovement = async () => {
     await createIDSEMovement({
      variables:{
        input: {
          tipo: 'Baja',
          idUser: Number(allSettlement.idCollaborator),
          idSettlement: allSettlementCollaborator[0].idSettlement,
          settlementType: selectedType === 'Liquidación' ? 'Liquidación' : 'Finiquito'
        }
      }
    });
    setType('');
  }

  const onChangeFile = async (e:any, type:string) => {
    LoadingAlert({title:'Subiendo archivos...'});
    try{
      const result = await postFileGeneral(id, e.target.files[0], type+"_"+(allSettlementCollaborator[0].id), "nominas");
      if(result){        
        await updateLetters({
          variables:{
            id: allSettlementCollaborator[0].id,
            input: {
              type: type,
              url: result
            }
          }
        });
      }
      Swal.close();
    }catch(e){
      Swal.close();
      ErrorAlert({text: 'Ocurrió un error al subir el documento'})
    }
  }

  const handleClick = (event:any) => {
    hiddenFileInput.current.click();
  };

  const handleClickQuit = (event:any) => {
    hiddenFileInputQuit.current.click();
  };

  /*Funciones*/
  const updateState = (id: any) => {
    if(!allSettlement?.dateFinish){
      updatePayroll({
        variables: {
          input: {
            id: id,
            statusProgress: "Dispersar",
          },
        },
      })
      history.push(`/SettlementDispersar/${id}/${tab}/${tipo}`)
    }else{
      history.push(`/SettlementDispersar/${id}/${tab}/${tipo}`)
    }
  };

  const updateStateTerminar = (idPayroll: any) => {    
    const terminado: any = settlement_payrolls?.filter((lis:any) => lis?.status === 'Terminado' )
    terminado?.length === 0? openFinish({idPayroll}, dispatch): history.push(`/PayrollTerminarFiniquito/${id}/${tab}`)
      
  };

  useEffect(() => {
    if(allSettlementCollaborator){
      let total = 0;
      allSettlementCollaborator?.forEach((item: any) => {
        total += item.total;
      });
      setImpoTotal(total);
      setSettlement_payrolls(allSettlementCollaborator)      
      if(allSettlementCollaborator[0].inMovimientosIDSE === 0 ){
        switch (tipo) {
          case 'Liquidación':
            if(allSettlementCollaborator[0].liquidationLetterLink !== null ){
              setFilesAdd([...filesAdd,allSettlementCollaborator[0].liquidationLetterLink])
            } 
          break;
          case 'Finiquito':
            const links = allSettlementCollaborator.reduce((acc: any[], item: { settlementLetterLink: any; resignationLetterLink: any; }) => {
              if (item.settlementLetterLink) acc.push(item.settlementLetterLink);
              if (item.resignationLetterLink) acc.push(item.resignationLetterLink);
              return acc;
          }, []);
          setFilesAdd(links)
          break;        
        }   
        setInIdse(true)
       } else{
        setInIdse(false)
       } 
    }
  }, [allSettlementCollaborator]);

  const exportarPDF = async (id: any) => {
    await axios.get(`${server}/report/documentosFiniquito/${id}`)
    const PDF: any = await axios.get(`${server}/report/documentosFiniquitoDescargar/${id}`)
    const link = document.createElement("a");
    link.href = PDF?.data?.urlPDF;
    link.setAttribute("download", "downloadtesting.PDF");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const exportarPDFL = async (id: any) => {
    await axios.get(`${server}/report/documentosLiquidacion/${id}`)
    const PDF: any = await axios.get(`${server}/report/documentosLiquidacionDescargar/${id}`)
    const link = document.createElement("a");
    link.href = PDF?.data?.urlPDF;
    link.setAttribute("download", "downloadtesting.PDF");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  
  return(
    <>
      <Box mt={3} ml={5} className="Title">
        Nóminas 
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid
          container
          justify="flex-start"
        >
          <CustomTabs
            value={tab}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}>
              </div>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
          </div>
        </div>
        <div className={styles.pc_contenedorBotones}>
          {allSettlement?.recessionJob === "Finiquito" ?(
            <a  
              onClick={() => {
                exportarPDF(id);
              }}
              target="_blank"
              rel="noreferrer"
              className={styles.fl_botonDescargar}
            >
                <div className={styles.fl_iconoDescargar}></div>
              <div>
                Descargar Documentos
              </div>
            </a>
          ):
          <a  
          onClick={() => {
            exportarPDFL(id);
          }}
          target="_blank"
          rel="noreferrer"
          className={styles.fl_botonDescargar}
        >
            <div className={styles.fl_iconoDescargar}></div>
          <div>
            Descargar Documentos
          </div>
        </a>

          }
          {CheckPermission("Nominas.FiniquitoLiquidacion.Ver.Calcular.Calcular") && (
            <button className={styles.pc_botonCalcular}>
              <div className={styles.pc_iconoCalcular}></div>
                Calcular
            </button>
          )}
          {CheckPermission("Nominas.FiniquitoLiquidacion.Ver.Calcular.IrADispersar") && (
            allSettlementCollaborator?
              impoTotal < 0?
              <button 
                className={styles.pc_botonDispersar}
                onClick={() =>{
                  updateStateTerminar(id);
                } }>
                <div>
                Ir a Terminar
                </div>
                <div className={styles.pc_iconoDispersar}></div>
              </button>
              
             :
              allSettlementCollaborator &&
              allSettlementCollaborator[0] &&
              allSettlementCollaborator[0].settlementLetterLink &&
              allSettlementCollaborator[0].resignationLetterLink ? (
                <button
                  className={styles.pc_botonDispersar}
                  onClick={() => {
                    updateState(id);
                  }}
                >
                  <div>Ir a Dispersar</div>
                  <div className={styles.pc_iconoDispersar}></div>
                </button>
              ) : 
              allSettlementCollaborator[0].liquidationLetterLink?
              (
                <button 
                className={styles.pc_botonDispersar}
                onClick={() => {
                  updateState(id);
                }}
                >
                  <div>Ir a Dispersar</div>
                  <div className={styles.pc_iconoDispersar}></div>
                </button>
              ):
              (
                <button className={styles.pc_botonDispersar} style={{ opacity: 0.5 }}>
                  <div>Ir a Dispersar</div>
                  <div className={styles.pc_iconoDispersar}></div>
                </button>
              )
            :null
          )}

          
        </div>
      </div>
      {settlement_payrolls?.map((lis:any)=>(
      <div className={styles.pc_contenedorPrincipal}>
        <div className={styles.fl_tituloContenedorPrincipal}>
          <div className={styles.pc_contenedorNombre}>
            <div>
              <span className={styles.pc_tituloPrincipal}>{tab}</span>
            </div>
          </div>
        </div>
        <div className={styles.p_contenedorTablaDispersion}>
          <table className={styles.pd_tablaPrincipal}>
            <thead className={styles.pd_contenedorTitulos}>
              <td className={`${styles.pd_columnaTitulosFiniquito} ${styles.fl_primeraFila}`}>Conceptos</td>
              <td className={styles.pd_columnaTitulosFiniquito}>D&iacute;as</td>
              <td className={styles.pd_columnaTitulosFiniquito}>Gravable </td>
              <td className={styles.pd_columnaTitulosFiniquito}>No Gravable</td>
              <td className={styles.pd_columnaTitulosFiniquito}>Total</td>
            </thead>
                  <tbody className={styles.pc_contenedorTitulos}>
                  <tr className={styles.pc_bordePrimeraColumna}>
                  <td className={styles.pc_primeraColumnaDatosFiniquito}>Sueldos</td>
                  <td className={styles.pc_columnaDatos}>{lis?.SueldoDias}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.salary)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.salaryTaxable)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.salary+lis?.salaryTaxable)}</td>
                </tr>
                {allSettlement?.recessionJob === "Liquidación" ?(
                  <>
                    <tr className={styles.pc_bordePrimeraColumna}>
                    <td className={styles.pc_primeraColumnaDatosFiniquito}>90 dias sueldo</td>
                      <td className={styles.pc_columnaDatos}>-</td>
                      <td className={styles.pc_columnaDatos}>{formatter(lis?.settlementDaysSalary)}</td>
                      <td className={styles.pc_columnaDatos}>{formatter(lis?.settlementDaysSalaryNoTaxable)}</td>
                      <td className={styles.pc_columnaDatos}>{formatter(lis?.settlementDaysSalary+lis?.settlementDaysSalaryNoTaxable)}</td>
                    </tr>
                    <tr className={styles.pc_bordePrimeraColumna}>
                    <td className={styles.pc_primeraColumnaDatosFiniquito}>Prima de antiguedad</td>
                      <td className={styles.pc_columnaDatos}>-</td>
                      <td className={styles.pc_columnaDatos}>{formatter(lis?.PrimaAntiguedad)}</td>
                      <td className={styles.pc_columnaDatos}>-</td>
                      <td className={styles.pc_columnaDatos}>{formatter(lis?.PrimaAntiguedad)}</td>
                    </tr>
                </>
                ):null
                }
                <tr className={styles.pc_bordePrimeraColumna}>
                  <td className={styles.pc_primeraColumnaDatosFiniquito}>Vacaciones</td>
                  <td className={styles.pc_columnaDatos}>{lis?.VacacionesDias}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.holidays)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.holidaysTaxable)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.holidays+lis?.holidaysTaxable)}</td>
                </tr>
                <tr className={styles.pc_bordePrimeraColumna}>
                  <td className={styles.pc_primeraColumnaDatosFiniquito}>Prima vacacional</td>
                  <td className={styles.pc_columnaDatos}>{lis?.PrimaVacacionalDias}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.primaVacacional)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.PrimaVacacionalTaxable)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.primaVacacional+lis?.PrimaVacacionalTaxable)}</td>
                </tr>
                <tr className={styles.pc_bordePrimeraColumna}>
                <td className={styles.pc_primeraColumnaDatosFiniquito}>Aguinaldo</td>
                  <td className={styles.pc_columnaDatos}>{lis?.AguinaldoDias}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.aguinaldo)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.aguinaldoTaxable)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.aguinaldo+lis?.aguinaldoTaxable)}</td>
                </tr>
                <tr className={styles.pc_bordePrimeraColumna}>
                  <td className={styles.pc_primeraColumnaDatosFiniquito}>Subsidio aplicado</td>
                  <td className={styles.pc_columnaDatos}>-</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.Subsidio)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.SubsidioNoGravable)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.Subsidio+lis?.SubsidioNoGravable)}</td>
                </tr>
                <tr className={styles.pc_bordePrimeraColumna}>
                  <td className={styles.pc_primeraColumnaDatosFiniquito}>Infonavit</td>
                  <td className={styles.pc_columnaDatos}>{'-'}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.Infonavit)}</td>
                  <td className={styles.pc_columnaDatos}>{'-'}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.Infonavit)}</td>
                </tr>
                <tr className={styles.pc_bordePrimeraColumna}>
                  <td className={styles.pc_primeraColumnaDatosFiniquito}>IMSS</td>
                  <td className={styles.pc_columnaDatos}>-</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.IMSS)}</td>
                  <td className={styles.pc_columnaDatos}>{'-'}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.IMSS)}</td>
                </tr>
                <tr className={styles.pc_bordePrimeraColumna}>
                  <td className={styles.pc_primeraColumnaDatosFiniquito}>Incidencias</td>
                  <td className={styles.pc_columnaDatos}>-</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.incident)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.incidentNoGravable)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.incident + lis?.incidentNoGravable)}</td>
                </tr>
                <tr className={styles.pc_bordePrimeraColumna}>
                  <td className={styles.pc_primeraColumnaDatosFiniquito}>ISR calculado</td>
                  <td className={styles.pc_columnaDatos}>-</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.taxes)}</td>
                  <td className={styles.pc_columnaDatos}>-</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.taxes)}</td>
                </tr>
                <tr className={styles.pd_contenedorTitulos}>
                  <td className={`${styles.pd_columnaTitulosFiniquito} ${styles.fl_primeraFila}`}>Total a pagar</td>
                  <td className={styles.pd_columnaTitulosFiniquito}></td>
                  <td className={styles.pd_columnaTitulosFiniquito}>{formatter(lis?.total)}</td>
                  <td className={styles.pd_columnaTitulosFiniquito}>{formatter(lis?.totalTaxable)}</td>
                  <td className={styles.pd_columnaTitulosFiniquito}>{formatter((lis?.salary+lis?.salaryTaxable+lis?.holidays + lis?.holidaysTaxable + lis?.primaVacacional+lis?.PrimaVacacionalTaxable+lis?.aguinaldo+lis?.aguinaldoTaxable+lis?.Subsidio+lis?.SubsidioNoGravable+lis?.incident + lis?.incidentNoGravable + lis?.settlementDaysSalary+lis?.settlementDaysSalaryNoTaxable + lis?.PrimaAntiguedad) - (lis?.IMSS + lis?.taxes + lis?.Infonavit))}</td>
                </tr>
                </tbody>
          </table>
        </div>
        <div className={styles.pc_contenedorTablaBaja}>
          <div className={styles.pc_contendedorFechas}>
              <div className={styles.pc_contenedorFechasInterno}>
              <div className={styles.pc_tituloFecha}>Fecha de ingreso</div>
              <div className={styles.pc_fechaInterno}>{lis?.FechaIngreso}</div>
            </div>
            <div className={styles.pc_contenedorFechasInterno}>
              <div className={styles.pc_tituloFecha}>Fecha de baja</div>
              <div className={styles.pc_fechaInterno}>{lis?.FechaBaja}</div>
            </div>
            <div className={styles.pc_contenedorFechasInterno}>
              <div className={styles.pc_tituloFecha}>Salario Diario</div>
              <div className={styles.pc_fechaInterno}>{formatter(lis?.DS)}</div>
            </div>
            <div className={styles.pc_contenedorFechasInterno}>
              <div className={styles.pc_tituloFecha}>Salario Diario Integrado</div>
              <div className={styles.pc_fechaInterno}>{formatter(lis?.ISD)}</div>
            </div>

          </div>

          <div className={styles.pc_contendedorFondoAhorro}>
              <div className={styles.pc_contenedorFechasInterno}>
              <div className={styles.pc_tituloFecha}>Fondo de ahorro</div>
              <div className={styles.pc_fechaInterno}>{formatter(lis?.FondoAhorro)}</div>
            </div>
          </div>      

          {allSettlement?.recessionJob === "Finiquito" ?(

          <div className={styles.pc_contenedorBajoArchivos}>
            <div className={styles.pc_contenedorInternoBotonesFiniquito}>
              <div className={styles.pc_contenedorTextoBotonesFiniquito}>
                <div className={styles.fl_textoArchivosInterno}>Carta finiquito</div>
                {
                  allSettlementCollaborator && allSettlementCollaborator[0] && allSettlementCollaborator[0].settlementLetterLink && (
                    <div className={styles.fl_contenedorTextoArchivos}>
                      <div className={styles.fl_iconoVerDocumento}></div>
                      <div className={styles.fl_textoVerDocumento}><a href={allSettlementCollaborator[0].settlementLetterLink} target={'_blank'} rel={'noreferrer'}>Ver documento</a></div>
                    </div>
                  )
                }
              </div>
              <div>
              <input type="file" name="Image" onChange={(e) => onChangeFile(e,'finiquito')} accept=".pdf" ref={hiddenFileInput} style={{display: 'none'}}/>
              {
                allSettlementCollaborator && allSettlementCollaborator[0] && allSettlementCollaborator[0].settlementLetterLink ? (           
                  <button className={styles.fl_botonSinDocumento} style={{cursor:'pointer'}} onClick={handleClick}>
                    <div className={styles.fl_iconoSubirArchivo}></div>
                      Cambiar archivo
                  </button>
                ):(
                  <button className={styles.fl_botonSinDocumento} style={{cursor:'pointer'}} onClick={handleClick}>
                    <div className={styles.fl_iconoSubirArchivo}></div>
                      Subir archivo
                  </button>
                )
              }
              </div>
            </div>
            <div className={styles.pc_contenedorInternoBotonesFiniquito}>
              <div className={styles.pc_contenedorTextoBotonesFiniquito}>
                <div className={styles.fl_textoArchivosInterno}>Carta renuncia</div>
                {
                  allSettlementCollaborator && allSettlementCollaborator[0] && allSettlementCollaborator[0].resignationLetterLink && (
                    <div className={styles.fl_contenedorTextoArchivos}>
                      <div className={styles.fl_iconoVerDocumento}></div>
                      <div className={styles.fl_textoVerDocumento}><a href={allSettlementCollaborator[0].resignationLetterLink} target={'_blank'} rel={'noreferrer'}>Ver documento</a></div>
                    </div>
                  )
                }
              </div>
              <div>
              <input type="file" name="Image" onChange={(e) => onChangeFile(e, 'renuncia')} accept=".pdf" ref={hiddenFileInputQuit} style={{display: 'none'}}/>
              {
                allSettlementCollaborator && allSettlementCollaborator[0] && allSettlementCollaborator[0].resignationLetterLink ? (    
                  <button className={styles.fl_botonSinDocumento} style={{cursor:'pointer'}} onClick={handleClickQuit}>
                    <div className={styles.fl_iconoSubirArchivo}></div>
                      Cambiar archivo
                  </button>
                ):(    
                  <button className={styles.fl_botonSinDocumento} style={{cursor:'pointer'}} onClick={handleClickQuit}>
                    <div className={styles.fl_iconoSubirArchivo}></div>
                      Subir archivo
                  </button> 
                )
              }
              </div>
            </div>
          </div>
          ):
          <div className={styles.pc_contenedorBajoArchivos}>
             <div className={styles.pc_contenedorInternoBotonesFiniquito}>
              <div className={styles.pc_contenedorTextoBotonesFiniquito}>
                <div className={styles.fl_textoArchivosInterno}>Carta Liquidaci&oacute;n</div>
                {
                  allSettlementCollaborator && allSettlementCollaborator[0] && allSettlementCollaborator[0].liquidationLetterLink && (
                    <div className={styles.fl_contenedorTextoArchivos}>
                      <div className={styles.fl_iconoVerDocumento}></div>
                      <div className={styles.fl_textoVerDocumento}><a href={allSettlementCollaborator[0].liquidationLetterLink} target={'_blank'} rel={'noreferrer'}>Ver documento</a></div>
                    </div>
                  )
                }
              </div>
              <div>
              <input type="file" name="Image" onChange={(e) => onChangeFile(e,'liquidacion')} accept=".pdf" ref={hiddenFileInput} style={{display: 'none'}}/>
              {
                allSettlementCollaborator && allSettlementCollaborator[0] && allSettlementCollaborator[0].liquidationLetterLink ? (           
                  <button className={styles.fl_botonSinDocumento} style={{cursor:'pointer'}} onClick={handleClick}>
                    <div className={styles.fl_iconoSubirArchivo}></div>
                      Cambiar archivo
                  </button>
                ):(
                  <button className={styles.fl_botonSinDocumento} style={{cursor:'pointer'}} onClick={handleClick}>
                    <div className={styles.fl_iconoSubirArchivo}></div>
                      Subir archivo
                  </button>
                )
              }
              </div>
            </div>
          </div>

          }
          
        </div>
        <div className={styles.pd_contenedorBarraEstado}>
          <div className={styles.pd_barraEstado} >
          <div className={styles.pd_contenedorRegreso}
              onClick={() => history.push(`/payroll`)}
            >
              <div className={styles.pd_botonRegreso}></div>
              <div>Regresar a "Finiquito/Liquidaci&oacute;n"</div>
            </div>
              <button className={styles.pd_botonSalir}
                onClick={() => history.push(`/payroll`)}
              >
                Salir
              </button>
          </div>
        </div>
      </div>
      ))
    }
    </>
  )

}

export default InitSettlementL