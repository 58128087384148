import Swal from "sweetalert2";
import iconWarning from '../../src/assets/svg/modal_warning.svg'

export const WarningAlertTimbre = ({
    text = '',
}) => Swal.fire({
    color: '#222222',
    title: '¡Atención!',
    html: `<label> ${text} </label>`,
    
    confirmButtonText: 'Cerrar',
    confirmButtonColor: '#FABB00',
    buttonsStyling: true,
    imageUrl: iconWarning,
    imageWidth: "77.46 px",
    imageHeight: "77.47 px",
    customClass: {
        title: 'Title',
        htmlContainer: 'text-color-sweetalert',
    },
    allowOutsideClick: false,
    showCloseButton: true,
    focusConfirm: false,
    focusDeny: false,
    focusCancel: false,
    width: '850px'
});