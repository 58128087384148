import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import styles from "../../src/components/Payroll/PayrollStyles.module.css";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import { GET_COLLABORATORS_DISPERSION, GET_PAYROLL_DISPERSION_2, GET_ALL_DISPERSIONLAYOUT, UPDATE_PAYROLL_STATE, GET_ALL_PAYROLL_ACTIVE, GET_ALL_PAYROLL, GET_ALL_COMPANY } from "../Querys/querys";
import { useContext, useEffect, useState, useCallback } from "react";
import { getDateDay, getDateYear } from "../helpers/Payroll/Payroll";
import { formatter } from "../helpers/formatoMoneda";
import SinNominasModal from "../components/Payroll/Modals/SinNominasModal";
import PayrollProcessContext from "../context/PayrollProcess/PayrollProcessContext";
import FondosInsufucientesModal from '../components/Payroll/Modals/FondosInsuficientesModal';
import AceptarDispersar from '../components/Payroll/Modals/AceptarDispersar'
import {
  openfondosInsuficientes,
  openaceptarDispersar,
  openSinNominasSeleccionadas,
  openAceptarDispersarIndividual,
  openModalOutsorcersResumenActivo,
  openModalDispersionEnProceso,
  openModalAlertaNoGravable
} from "../context/PayrollProcess/Actions";
import AceptarDispersarIndividual from "../components/Payroll/Modals/AceptarDispersarIndividual";
import { CreateExcelFilePayrollDispersar } from "../components/Payroll/Modals/ExcelPayrollD";
import { CreateExcelFilePayrollPNL } from "../components/Payroll/Modals/ExcelPayrollPNL";
import LockedSystem from "../alerts/LockedSystem";
import ResumenOutsourcers from "../components/Payroll/Modals/Outsourcers/ResumenOutsourcers";
import DispersionEnProceso from "../components/Payroll/Modals/DispersionEnProcesoPayroll";
import AlertaNoGravable from "../components/Payroll/Modals/AlertaNoGravable";
import CheckPermission from "../helpers/Administradores/Permissions";
import { removeAccents } from '../helpers/RemoveAccents';

const PayrollDispersion = () => {
  const { dispatch } = useContext(PayrollProcessContext)
  const { id, tab } = useParams<any>();
  const history = useHistory();
  const [cargandoPrincipal, setCargandoPrincipal] = useState(true)
  const [inicio, setInicio] = useState(0)
  const [total, setTotal] = useState(0)
  const [disponibleDispersar, setDisponibleDispersar] = useState(true)
  const [diferencia, setDiferencia] = useState(0)
  const [filtrado, setFiltrado] = useState<any[]>([])
  const [datosTablas, setDatosTablas] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const [isCheck, setIsCheck] = useState<any[]>([])
  const [procesoDispersion, setProcesoDispersion] = useState(false)
  const [barraPorcentaje, setBarraPorcentaje] = useState(0)
  const [dispersados, setDispersados] = useState(0)
  const [randomRequest, setRandomRequest] = useState(Math.random() * 10000);
  const [userDispersarIndividual, setUserDispersarIndividual] = useState({ id: 0, nombre: '', montoDispersar: 0 });
  const handleChange = () => {
  }
  //Datos principales y headers
  const { loading: loadingPrincipal, data: dataPrincipal } = useQuery(GET_PAYROLL_DISPERSION_2, {
    variables: {
      getPayrollDispersionId: id,
      randomRequest,
    }
  })

  const PayrollData = dataPrincipal?.GET_PAYROLL_DISPERSION_2

  //Datos de tablas
  const { data: dataDispersion, loading: loadingTabla, startPolling, stopPolling } = useQuery(GET_COLLABORATORS_DISPERSION, {
    variables: { getCollaboratorsDispersionId: id },
  });

  const allPayrollDispersion = dataDispersion?.GET_COLLABORATORS_DISPERSION
  //Datos de archivos
  const { data: dataDipsersionExcell, startPolling: startPollingLayout, stopPolling: stopPollingLayout } = useQuery(GET_ALL_DISPERSIONLAYOUT, {
    variables: { getAllDispersionlayoutId: id },
  });

  const allPayrollCollaboratorExcell = dataDipsersionExcell?.GET_ALL_DISPERSIONLAYOUT;

  useEffect(() => {
    startPollingLayout(1000);
    return () => {
      stopPollingLayout();
    };
  }, [startPollingLayout, stopPollingLayout]);

  const [updatePayroll] = useMutation(UPDATE_PAYROLL_STATE, {
    refetchQueries: [{ query: GET_ALL_PAYROLL_ACTIVE }, { query: GET_ALL_PAYROLL }]
  });

  const datosIniciales = useCallback(() => {
    setCargandoPrincipal((current) => current = false)
    setInicio((current) => current = PayrollData?.saldoCuenta)
  }, [PayrollData]);

  const busqueda = (e: any) => {
    setSearch((current) => current = e.target.value)
    guardaPaginado()
  }

  const getDatosTabla = useCallback(async () => {
    setDatosTablas([...allPayrollDispersion]);
    const tokenActual = PayrollData?.tokenActual;

    let registrosConTockenEnEspera = (allPayrollDispersion.filter((e: any) =>
      e.tokenDispersar === tokenActual &&
      (e.dispersionStatus === 'LIQUIDADO' || e.dispersionStatus === 'ERROR' || e.dispersionStatus === 'DEVUELTO'))).length;
    let porcentajeDispersados = (registrosConTockenEnEspera / PayrollData?.numeroRegistrosToken) * 100;
    setBarraPorcentaje((current) => current = porcentajeDispersados);
    let registrosLiquidado = (allPayrollDispersion.filter((e: any) => e.dispersionStatus === 'LIQUIDADO')).length;
    setDispersados((current) => current = registrosLiquidado);
  }, [PayrollData, allPayrollDispersion]);

  const guardaPaginado = useCallback(() => {
    const results = !search ? datosTablas : datosTablas?.filter((dato) => {
      // Eliminar acentos de la cadena de búsqueda y del campo de la tabla
      const searchTermWithoutAccents = removeAccents(search.toLocaleLowerCase());
      const datoWithoutAccents = removeAccents(dato.colaborator.toLowerCase());
      return datoWithoutAccents.includes(searchTermWithoutAccents);
    });

    setFiltrado((current) => current = results);
  }, [datosTablas, search]);

  const handleSelectAll = (e: any) => {
    if (!e.target.checked) {
      setIsCheck([])
    }
    let filtradoSeleccionado = (allPayrollDispersion.filter((lis: any) => {
      return e.target.checked && !isCheck.includes(lis.id) && (lis.dispersionStatus === "EnEspera" || lis.dispersionStatus === "ERROR" || lis.dispersionStatus === "DEVUELTO")
    }).map((lis: any) => (lis.id)))
    setIsCheck(filtradoSeleccionado)
  }

  const handleClick = (e: any) => {
    const { id, checked } = e.target
    setIsCheck([...isCheck, id])
    if (!checked) {
      setIsCheck(isCheck.filter((lis: any) => lis !== id))
    }
  }

  const actualizaSaldos = useCallback(() => {
    let arrayTotales = (allPayrollDispersion?.filter((lis: any) => {
      return isCheck.includes(lis.id)
    }).map((lis: any) => (lis.netIncomeTaxable)))
    if (arrayTotales?.length > 0) {
      const suma = (acc: any, curr: any) => acc + curr;

      setTotal(arrayTotales.reduce(suma));
      setDiferencia(inicio - arrayTotales.reduce(suma));

      if ((inicio - arrayTotales.reduce(suma)) >= 0) {
        setDisponibleDispersar(true)
      } else {
        setDisponibleDispersar(false)
      }

    } else {
      setTotal(0)
      setDiferencia(0)
      setDisponibleDispersar(true)
    }
  }, [allPayrollDispersion, inicio, isCheck]);

  const reinicioDatos = () => {
    setIsCheck([])
    actualizaSaldos()
  }

  const reiniciaDatosIndividual = () => {
    setIsCheck([])
    actualizaSaldos()
    setUserDispersarIndividual({ id: 0, nombre: '', montoDispersar: 0 })
  }

  const handleOnExport = (Tipo: any) => {
    if (Tipo === 'Layout') {
      CreateExcelFilePayrollPNL(allPayrollCollaboratorExcell);
    } else {
      CreateExcelFilePayrollDispersar(allPayrollDispersion);
    }
  }

  //Alertas
  const openFondosInsuficientes = () => {
    openfondosInsuficientes({ aceptarDisperar: true }, dispatch)
  }

  const openSinSeleccion = () => {
    openSinNominasSeleccionadas({ sinNominasSeleccionadas: true }, dispatch);
  }

  const openAceptaDispersar = () => {
    openaceptarDispersar({ aceptarDispersar: true }, dispatch)
  }

  const abreAceptarDispersarIndividual = (id: any) => {
    openAceptarDispersarIndividual({ aceptarDispersarIndividual: true, id }, dispatch)
  }

  const abreResumenOutsourcers = () => {
    openModalOutsorcersResumenActivo({ openModalOutsourcersResumen: true }, dispatch)
  }

  const abreDispersionEnProceso = () => {
    openModalDispersionEnProceso({ openModalDispersionEnProceso: true }, dispatch)
  }

  const abreModalAlertaNoGravable = () => {
    openModalAlertaNoGravable({ openAlertaNoGravable: true }, dispatch)
  }

  /*--------------------------sistemaActivo--------------------*/
  const { data: resultCompany, startPolling: startPollingSistemaActivo, stopPolling: stopPollingSistemaActivo } = useQuery(GET_ALL_COMPANY);
  const myCompany = resultCompany?.GET_ALL_COMPANY;
  const [sistemaActivo, setSistemaActivo] = useState<any>(true);

  useEffect(() => {
    startPollingSistemaActivo(1000);
    return () => {
      stopPollingSistemaActivo()
    }
  }, [startPollingSistemaActivo, stopPollingSistemaActivo])

  useEffect(() => {
    if (myCompany) {
      setSistemaActivo(myCompany[0]?.SistemaActivo)
    }
  }, [myCompany]);
  /*--------------------------sistemaActivo--------------------*/

  const updateDataAfterDispersar = () => {
    setRandomRequest(Math.random() * 10000);
  }

  const dispersionIndividualNombre = (id: any, nombre: any, montoDispersar: any) => {

    const e = { target: { id, checked: true } }
    handleClick(e)
    actualizaSaldos()
    setUserDispersarIndividual({ id, nombre, montoDispersar })
    if (total >= montoDispersar) {
      openFondosInsuficientes()
      reiniciaDatosIndividual()
      return
    }
    if (PayrollData?.dispersionEnProceso > 0) {
      abreDispersionEnProceso()
      reiniciaDatosIndividual()
      return
    }
    openAceptarDispersarIndividual({ aceptarDispersarIndividual: true, id }, dispatch)
  }


  useEffect(() => {
    if (dataPrincipal) {
      datosIniciales()
      actualizaSaldos()
    }
  }, [dataPrincipal, actualizaSaldos, datosIniciales])

  useEffect(() => {
    if (datosTablas) {
      guardaPaginado()
    }
  }, [datosTablas, search, guardaPaginado])

  useEffect(() => {
    if (allPayrollDispersion) {
      getDatosTabla();
      actualizaSaldos()
    }
    startPolling(1000);
    return () => {
      stopPolling()
    }
  }, [allPayrollDispersion, startPolling, stopPolling, isCheck, actualizaSaldos, getDatosTabla])


  const updateState = (id: any) => {
    if (!PayrollData?.dateFinish) {
      updatePayroll({
        variables: {
          input: {
            id: id,
            statusProgress: "Timbrar",
          },
        },
      });
    }
  };

  console.log('Este es el resultado', PayrollData)

  return (
    <>
      {!sistemaActivo ? (
        <LockedSystem />
      ) : null}
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            //Tiene que ser 1 para que se muestre la pestaña de pre-nómina
            value={1}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
              <CustomTab label="Finiquito / Liquidación" value={2} />)}
            {CheckPermission("Nominas.Eventuales") && (
              <CustomTab label="Eventuales" value={3} />)}
            {CheckPermission("Nominas.Historicas") && (
              <CustomTab label="Históricas" value={4} />)}
            {CheckPermission("Nominas.Calculadora") && (
              <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
              <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}>
              </div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}>
            </div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}>
              </div>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
          </div>
        </div>
        {(cargandoPrincipal === false) ?
          <div className={styles.pc_contenedorBotones}>
            {(CheckPermission("Nominas.PreNomina.Ver.Dispersar.Dispersar")) && (
              <>
                {
                  sistemaActivo ?
                    (procesoDispersion === false) ?
                      (isCheck.length > 0) ?
                        (disponibleDispersar) ?
                          (PayrollData?.dispersionEnProceso > 0) ?
                            <button
                              className={styles.pd_botonDispersarDos}
                              onClick={() => abreDispersionEnProceso()}
                            >
                              <div className={styles.pd_iconoDispersarDos}></div>
                              Dispersar
                            </button>
                            :
                            <button
                              className={styles.pd_botonDispersarDos}
                              onClick={() => openAceptaDispersar()}
                            >
                              <div className={styles.pd_iconoDispersarDos}></div>
                              Dispersar
                            </button>
                          :
                          <button
                            className={styles.pd_botonDispersarDosInactivo}
                            onClick={() => openFondosInsuficientes()}
                          >
                            <div className={styles.pd_iconoDispersarDos}></div>
                            Dispersar
                          </button>
                        :
                        <button
                          className={styles.pd_botonDispersarDosInactivo}
                          onClick={() => openSinSeleccion()}
                        >
                          <div className={styles.pd_iconoDispersarDos}></div>
                          Dispersar
                        </button>
                      :
                      <button
                        className={styles.pd_botonDispersarDosInactivo}
                        onClick={() => openSinSeleccion()}
                      >
                        <div className={styles.pd_iconoDispersarDos}></div>
                        Dispersar
                      </button>
                    : null
                }
              </>
            )}
            {(CheckPermission("Nominas.PreNomina.Ver.Dispersar.IrATimbrar")) && (
              <>
                {(dispersados > 0) ?
                  <button
                    className={styles.pd_botonDispersarDos}
                    onClick={() => {
                      updateState(id);
                      history.push(`/payrolltimbrar/${id}/${tab}`);
                    }}
                  >
                    <div>Ir a Timbrar</div>
                    <div className={styles.pc_iconoDispersar}></div>
                  </button>
                  :
                  <button
                    className={styles.pd_botonDispersarDos}
                    style={{ opacity: 0.5 }}
                  >
                    <div>Ir a Timbrar</div>
                    <div className={styles.pc_iconoDispersar}></div>
                  </button>
                }
              </>
            )}
          </div>
          :
          null
        }
      </div>
      {(cargandoPrincipal === true || loadingPrincipal === true) ?
        <div className={styles.pc_contenedorPrincipal}>
          <div className={styles.contenedorLoading}>
            <div className={styles.loading}></div>
          </div>
        </div>
        :
        <div className={styles.pc_contenedorPrincipal}>
          <div className={styles.pc_tituloContenedorPrincipal}>
            <div className={styles.pc_contenedorNombre}>
              <div>
                <span className={styles.pc_tituloPrincipal}>{tab}</span>
              </div>
              <div>
                <span className={styles.pc_tituloPeriodo}>Periodo &nbsp;{getDateDay(PayrollData?.fechaInicio)}</span> &nbsp; - &nbsp;
                <span className={styles.pc_tituloPeriodo}>{getDateDay(PayrollData?.fechaFin)}</span>&nbsp;
                <span className={styles.pc_tituloPeriodo}>{getDateYear(PayrollData?.fechaFin)}</span>
              </div>
            </div>
            <div className={styles.pd_contenedorTituloSaldos}>
              <div className={styles.pd_cuentaBancaria}>
                <div className={styles.pd_textoCuentaBancaria}>
                  Cuenta Bancaria
                </div>
                <div className={styles.pd_contenedorCuentaBancaria}>
                  {PayrollData?.cuentaBancaria}
                </div>
              </div>
              <div className={styles.pd_contenedorSaldos}>
                <div className={styles.pd_contenedorInterno}>
                  <div className={styles.pd_tituloSaldos}>
                    Saldo disponible
                  </div>
                  <div className={styles.pd_saldoInterno}>
                    {formatter(inicio)}
                  </div>
                </div>
                <div className={styles.pd_contenedorInterno}>
                  <div className={styles.pd_tituloSaldos}>
                    Monto a pagar
                  </div>
                  <div className={styles.pd_saldoInterno}>
                    {formatter(total)}
                  </div>
                </div>
                <div className={styles.pd_contenedorInterno}>
                  <div className={styles.pd_tituloSaldos}>
                    Diferencia
                  </div>
                  {(disponibleDispersar) ?
                    <div className={styles.pd_saldoInterno}>
                      {formatter(diferencia)}
                    </div>
                    :
                    <div className={styles.pd_saldoInternoNegativo}>
                      {formatter(diferencia)}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={styles.pc_contenedorBuscadorDescargar}>
            <div className={styles.pc_contenedorBuscador}>
              <input
                value={search}
                className={styles.pc_inputBuscador}
                type="text"
                placeholder="Ingresa el nombre del colaborador"
                onChange={busqueda}
              />
              <div className={styles.pc_iconoBuscador}></div>
            </div>
            <div className={styles.contenedorBotonesDescargas}>
              <button
                className={styles.pc_botonDescargar}
                onClick={() => handleOnExport("Layout")}
              >
                <div className={styles.pc_iconoDescargar}></div>
                <div>
                  Descargar layout
                </div>
              </button>
              <button
                className={styles.pc_botonDescargar}
                onClick={() => handleOnExport("Reporte")}>
                <div className={styles.pc_iconoDescargar}></div>
                <div>
                  Descargar reporte
                </div>
              </button>
            </div>
          </div>
          {(CheckPermission("Nominas.PreNomina.Ver.Dispersar.Outsourcers")) && (
            <div className={styles.contenedorBotonOutosrcer}>
              {(PayrollData.dispersionGravable > 0) ?
                <button
                  className={styles.pd_botonOutosoursers}
                  onClick={() => history.push(`/outsorcersAutorizar/${id}/${tab}`)}
                >
                  <div>Outsourcers</div>
                  <div className={styles.pc_iconoDispersar}></div>
                </button>
                :
                <button
                  className={styles.pd_botonOutosoursers}
                  onClick={() => abreModalAlertaNoGravable()}
                >
                  <div>Outsourcers</div>
                  <div className={styles.pc_iconoDispersar}></div>
                </button>
              }
              {(PayrollData?.estatusOutsourcers) === 'Descartadas' ?
                <div
                  className={styles.po_botonDescartado}
                  onClick={() => abreResumenOutsourcers()}
                >
                  <div className={styles.po_textoBotonRechazado}>X</div>
                </div>
                : null
              }
              {(PayrollData?.estatusOutsourcers) === 'Autorizados' ?
                <div
                  className={styles.po_iconoPendiente}
                  onClick={() => abreResumenOutsourcers()}
                >
                </div>
                : null
              }
              {(PayrollData?.estatusOutsourcers) === 'Finalizados' ?
                <div
                  className={styles.po_botoneEnProceso}
                  onClick={() => abreResumenOutsourcers()}
                >
                  <div className={styles.po_iconoEstatusCuatro}></div>
                </div>
                : null
              }
            </div>
          )}
          <div className={styles.p_contenedorTablaDispersion}>
            <table className={styles.pd_tablaPrincipal}>
              <thead className={styles.pd_contenedorTitulos}>
                <td className={`${styles.pd_columnaTitulos} ${styles.pd_primerColumnaTitulo}`}>
                  <div id="colaboladores" className={styles.checkboxitem}>
                    <input
                      id="check"
                      type="checkbox"
                      onChange={(e) => handleSelectAll(e)}
                      disabled={loadingTabla ? true : false}
                    ></input>
                    <label htmlFor="check"> Colaboradores </label>
                  </div>
                </td>
                <td className={styles.pd_columnaTitulos}>RFC</td>
                <td className={styles.pd_columnaTitulos}>Banco</td>
                <td className={styles.pd_columnaTitulos}>Cuenta bancaria</td>
                <td className={styles.pd_columnaTitulos}>CLABE</td>
                <td className={styles.pd_columnaTitulos}>Total a dispersar por nómina</td>
                <td className={styles.pd_columnaTitulos}>Total a dispersar no gravable</td>
                <td id="payroll_9" className={styles.pd_columnaTitulos}>Estatus</td>
              </thead>
              <tbody className={styles.pc_contenedorTitulos}>
                {filtrado?.map((lis: any) => (
                  <tr className={styles.pc_bordePrimeraColumna}>
                    <td className={`${styles.pd_primerColumnaTitulo}`}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={lis?.id}
                          key={lis?.id}
                          type="checkbox"
                          checked={isCheck.includes(lis?.id)}
                          onChange={(e) => handleClick(e)}
                          value={lis?.id}
                          disabled={(lis?.dispersionStatus !== "EnEspera" && lis?.dispersionStatus !== "ERROR" && lis?.dispersionStatus !== "DEVUELTO") ? true : false}
                        ></input>
                        <label htmlFor={lis?.id} className={styles.datosLabel}>  {lis?.colaborator} </label>
                      </div>
                    </td>
                    <td className={styles.pc_columnaDatos}>{lis?.RFC}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.bank}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.accountNumber}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.clabeNum}</td>
                    <td className={styles.pc_columnaDatos}>{formatter(lis?.netIncomeTaxable)}</td>
                    <td className={styles.pc_columnaDatos}>
                      {lis?.Adelanto > 0 ? (
                        <div style={{ display: 'flex', alignItems: 'center' }} title={`Tiene un adelanto de $${lis?.Adelanto}`}>
                          {formatter(lis?.TotalIncomeNotTaxable)}
                          <div className={styles.pd_iconoError}></div>
                        </div>
                      ) : (
                        formatter(lis?.TotalIncomeNotTaxable)
                      )}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {(lis?.dispersionStatus === "ENPROCESO") ?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusDos}`}>
                          <div className={styles.pd_textoBotonEstatusDos}>
                            En proceso...
                          </div>
                        </div>
                        : null
                      }
                      {(lis?.dispersionStatus === "ERROR") ?
                        <div
                          onClick={() => dispersionIndividualNombre(lis.id, lis.colaborator, lis.netIncomeTaxable)}
                          className={styles.pd_botonEstatusTres}>
                          <div className={styles.pd_contenedorEstatusTres}>
                            <div className={styles.pd_contEstatusTres}>
                              <div className={styles.pd_iconoError}></div>
                              <div className={styles.pd_textoBotonError}>
                                Error al dispersar
                              </div>
                            </div>
                            <div className={styles.pd_textoBotonErrorDos}>
                              {lis.mensajeDispersion}
                            </div>
                          </div>
                          <div className={styles.pd_botonInternoDispersar}>
                            <div className={styles.pd_iconoIntentar}></div>
                          </div>
                        </div>
                        : null
                      }
                      {(lis?.dispersionStatus === "DEVUELTO") ?
                        <div
                          onClick={() => dispersionIndividualNombre(lis.id, lis.colaborator, lis.netIncomeTaxable)}
                          className={styles.pd_botonEstatusTres}>
                          <div className={styles.pd_contenedorEstatusTres}>
                            <div className={styles.pd_contEstatusTres}>
                              <div className={styles.pd_iconoError}></div>
                              <div className={styles.pd_textoBotonError}>
                                La institución contraparte ha rechazado el proceso
                              </div>
                            </div>
                            <div className={styles.pd_textoBotonErrorDos}>
                              {lis.mensajeDispersion}
                            </div>
                          </div>
                          <div className={styles.pd_botonInternoDispersar}>
                            <div className={styles.pd_iconoIntentar}></div>
                          </div>
                        </div>
                        : null
                      }
                      {(lis?.dispersionStatus === "LIQUIDADO") ?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                          <div className={styles.pd_textoBotonEstatusCuatro}>
                            Finalizado
                          </div>
                          <div className={styles.pd_iconoEstatusCuatro}></div>
                        </div>
                        : null
                      }
                      {(lis?.dispersionStatus === "ENVIANDO") ?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCinco}`}>
                          <div className={styles.pd_textoBotonEstatusCinco}>
                            Enviando
                          </div>
                        </div>
                        : null
                      }
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
          <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado} >
              <div className={styles.pd_contenedorRegreso}
                onClick={() => history.push(`/InitPayroll/${id}/${tab}`)}
              >
                <div className={styles.pd_botonRegreso}></div>
                <div>Regresar a "Calcular"</div>
              </div>
              <button className={styles.pd_botonSalir}
                onClick={() => history.push(`/payroll`)}
              >
                Salir
              </button>
            </div>
          </div>
        </div>
      }
      <SinNominasModal />
      <FondosInsufucientesModal />
      <AceptarDispersar
        totalesDispersar={isCheck?.length}
        totales={allPayrollDispersion?.length}
        seleccionados={isCheck}
        setProcesoDispersion={setProcesoDispersion}
        reinicia={() => reinicioDatos()}
        idRegistro={id}
        updateDataAfterDispersar={updateDataAfterDispersar}
      />
      <AceptarDispersarIndividual
        id={userDispersarIndividual.id}
        nombre={userDispersarIndividual.nombre}
        reinicia={() => reiniciaDatosIndividual()}
      />
      <ResumenOutsourcers
        porAutorizar={PayrollData?.resumenPorAutorizarOutsourcers}
        autorizadas={PayrollData?.resumenAutorizadosOutsourcers}
        conRespuesta={PayrollData?.resumenConRespuestaOutsourcers}
      />
      <DispersionEnProceso
        updateDataAfterDispersar={updateDataAfterDispersar}
      />
      <AlertaNoGravable />
    </>
  )

}

export default PayrollDispersion